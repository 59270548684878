import moment from 'moment';
import { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { PlanBtn } from '../../common/Button';
import { cardBg } from '../../../constants/stylesConstants';
import Modal from '../../ModalWindows/Modal';
import PlanActivity from '../../ModalWindows/Common';
import planWorkOrder from '../../../coreFuncs/specific/planWorkOrder';
import { useRootContext } from '../../../context';
import {
  createActivity,
  removeUnplannedWorkOrder,
} from '../../../context/actions';
import { DATE_TIME_FORMAT } from '../../../constants/formats';

export default ({
  id,
  project,
  methods,
  description,
  addressLine,
  customer_name: customerName,
  preferred_date: preferedDate,
  work_order_number: workOrderNumber,
  board,
  grid,
}) => {
  const ref = useRef();
  const {
    dispatch,
    state: {
      employees,
      dimensions,
      isUpdateAll,
      employeePosition,
      allowedEmployees,
    },
  } = useRootContext();
  const [isShown, setIsShown] = useState(false);

  const createReqObj = useCallback((start, end, employeeId) => {
    const [newStartDate, newStartTime] = moment(start)
      .format(DATE_TIME_FORMAT)
      .split(' ');
    const [newEndDate, newEndTime] = moment(end)
      .format(DATE_TIME_FORMAT)
      .split(' ');

    return {
      type: 2,
      work_order_id: id,
      title: workOrderNumber,
      employee_id: employeeId,
      description: description || '',
      end_date: newEndDate,
      end_time: newEndTime,
      start_date: newStartDate,
      start_time: newStartTime,
    };
  }, []);

  const onDropCallback = useCallback(
    async ({ start, end, employeeIndex }) => {
      const employee = employees[employeeIndex];
      if (!employee) return false;

      const reqObj = createReqObj(start, end, employee.id);
      const success = await createActivity(dispatch, reqObj);
      if (success) removeUnplannedWorkOrder(dispatch, id);

      return !!success;
    },
    [employees, createReqObj, employeePosition]
  );

  const mouseDownHandler = useCallback(
    e => {
      planWorkOrder({
        e,
        employees,
        dimensions,
        onDropCallback,
        grid: grid.current,
        origin: ref.current,
        board: board.current,
        allowedEmployees,
        isUpdateAll,
      });
    },
    [dimensions, onDropCallback]
  );

  return (
    <>
      <Order ref={ref} onMouseDown={mouseDownHandler}>
        <Container>
          <MarginBottom>
            <Author>{customerName}</Author>
          </MarginBottom>

          <div>
            <Row>
              <Title>Project</Title>
              <Description title={project}>{project}</Description>
            </Row>

            <Row>
              <Title>Work location:</Title>
              <Description title={addressLine}>{addressLine}</Description>
            </Row>

            {methods.length ? (
              <Row>
                <Title>Method</Title>
                <Description title={methods.join(', ')}>
                  {methods.join(', ')}
                </Description>
              </Row>
            ) : null}

            {preferedDate && (
              <Row>
                <Title>Preferred date:</Title>
                <Description title={preferedDate}>{preferedDate}</Description>
              </Row>
            )}
          </div>
        </Container>

        <ButtonPosition>
          <PlanBtn
            onMouseDown={e => e.stopPropagation()}
            onClick={() => setIsShown(true)}
          >
            Plan
          </PlanBtn>
        </ButtonPosition>
      </Order>
      {isShown && (
        <Modal onHideModal={() => setIsShown(false)}>
          <PlanActivity
            modalType="plan"
            type={2}
            workOrderId={id}
            title={workOrderNumber}
            onHideModal={() => setIsShown(false)}
          />
        </Modal>
      )}
    </>
  );
};

const Order = styled.div`
  padding: 15px;
  background: ${cardBg};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: grab;
`;

const Container = styled.div`
  flex-grow: 2;
`;

const Author = styled.p`
  font-size: 13px;
  font-weight: 500;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: 300;
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Row = styled.div`
  font-size: 12px;
  display: flex;
  font-weight: 300;
  justify-content: space-between;
  margin-bottom: 9px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const ButtonPosition = styled.div`
  margin-top: 10px;
  align-self: flex-end;
`;

const MarginBottom = styled.div`
  margin-bottom: 15px;
`;
