import React from 'react';
import styled from 'styled-components';
import { secondaryBorderColor } from '../../../constants/stylesConstants';

export default ({
  projectData: {
    project_coordinator: coordinator,
    project_leader: leader,
    project_name: prName,
    project_establishment: establishmentName,
  },
}) => (
  <ProjectData>
    <H4>Project Data</H4>
    <Container>
      <Row>
        <Col>
          <Title>Project name:</Title>
        </Col>
        <DescriptionWrapper>
          <DescriptionUpperText>{prName}</DescriptionUpperText>
        </DescriptionWrapper>
      </Row>

      <Row>
        <Col>
          <Title>Project leader:</Title>
        </Col>
        <DescriptionWrapper>
          <Description>{leader}</Description>
        </DescriptionWrapper>
      </Row>

      <Row>
        <Col>
          <Title>Project coordinator:</Title>
        </Col>
        <DescriptionWrapper>
          <Description>{coordinator}</Description>
        </DescriptionWrapper>
      </Row>

      <Row>
        <Col>
          <Title>Establishment:</Title>
        </Col>
        <DescriptionWrapper>
          <Description>{establishmentName}</Description>
        </DescriptionWrapper>
      </Row>
    </Container>
  </ProjectData>
);

const ProjectData = styled.div`
  padding-bottom: 10px;
  border-bottom: 2px solid ${secondaryBorderColor};
`;

const H4 = styled.h4`
  font-size: 18px;
  font-weight: 700;
`;

const Container = styled.div`
  padding-top: 8px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const Title = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  vertical-align: middle;
  overflow-wrap: break-word;
`;

const DescriptionUpperText = styled(Description)`
  text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
    align-items: flex-start;
  }
`;

const Col = styled.div`
  flex-basis: 25%;
  max-width: 25%;
`;
