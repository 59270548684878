import styled from 'styled-components';
import { Spin } from 'antd';
import { FadeLoader } from 'react-spinners';
import { CenterFlex, flexCenter } from './styledComponents';

export const CenterFadeLoader = () => (
  <CenterFlex>
    <FadeLoader />
  </CenterFlex>
);

const SpinWrapper = styled.div`
  ${flexCenter}
  width: 100%;
`;

export const CenterAntSpin = () => (
  <SpinWrapper>
    <Spin />
  </SpinWrapper>
);
