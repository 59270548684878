import React from 'react';
import styled from 'styled-components';

import TelephoneIcon from '../../../../public/icons/telephone.svg';
import MessageIcon from '../../../../public/icons/message.svg';
import {
  primary,
  basic,
  transition,
} from '../../../../constants/stylesConstants';
import { getServerUrl } from '../../../../utils/apiUrls';

const serverUrl = getServerUrl();

export default ({ employeeId, phoneNumber, emailAddress }) => {
  return (
    <Container>
      <Row>
        <TelephoneIcon />
        <InfoLink
          className="employee-popup-link"
          href={phoneNumber ? `tel:${phoneNumber.replace(/\s/g, '')}` : '#'}
        >
          {phoneNumber || 'Private'}
        </InfoLink>
      </Row>

      <Row>
        <MessageIcon />
        <InfoLink
          className="employee-popup-link"
          href={emailAddress ? `mailto:${emailAddress}?` : '#'}
          target="_top"
        >
          {emailAddress || 'Private'}
        </InfoLink>
      </Row>
      <OutUserLink
        className="employee-popup-link"
        href={`${serverUrl}/hrm/employees/${employeeId}/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View employee
      </OutUserLink>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: content-box;
  padding: 10px 20px;
  display: flex;
  color: ${primary};
  flex-direction: column;
  justify-content: center;
  background: ${basic};
  height: fit-content;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: ${transition};

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: ${basic};
  }
`;

const InfoLink = styled.a`
  margin-left: 11px;
  text-decoration: none;
  color: inherit;
  line-break: loose;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
`;

const OutUserLink = styled.a`
  height: 21px;
  min-width: 154px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  text-decoration: none;
  color: ${basic};
  background-color: ${primary};
  font-size: 14px;
`;
