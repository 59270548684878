import moment from 'moment';
import styled from 'styled-components';
import { useMemo } from 'react';

import DayTitle from './DayTitle';
import {
  renderDayOffDayBgGrid,
  renderWorkingDayBgGrid,
} from '../../../utils/gradients';
import { getDayHoursNumber } from './helpers';
import {
  basic,
  secondary,
  secondaryBorderColor,
} from '../../../constants/stylesConstants';
import {
  HOUR_WIDTH,
  DAY_BORDER_WIDTH,
  DAY_TITLE_HEIGHT,
} from '../../../constants/continuum';

export default ({ scale, date, todayRef, dayClass }) => {
  const dayConfig = useMemo(() => {
    const momentDate = moment(date.date);
    const weekDay = momentDate.day();

    const todayIsDayOff = weekDay === 0 || weekDay === 6;
    const hoursNumber = getDayHoursNumber(momentDate);

    return { momentDate, hoursNumber, todayIsDayOff };
  }, [date]);

  return (
    <Day k={scale} ref={todayRef} className={dayClass}>
      <DayTitle
        scale={scale}
        dateStats={date}
        momentDate={dayConfig.momentDate}
        hoursNumber={dayConfig.hoursNumber}
      />
      <DayBody
        k={scale}
        hoursNumber={dayConfig.hoursNumber}
        todayIsDayOff={dayConfig.todayIsDayOff}
      />
    </Day>
  );
};

const Day = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;
`;

const DayBody = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'todayIsDayOff' && prop !== 'hoursNumber', // Filter out todayIsDayOff
})`
  height: ${() => `calc(100% - ${DAY_TITLE_HEIGHT}px)`};
  border-right: ${DAY_BORDER_WIDTH}px solid ${secondaryBorderColor};
  background-color: ${p => (p.todayIsDayOff ? secondary : basic)};
  background-image: ${p =>
    p.todayIsDayOff ? renderDayOffDayBgGrid(p.k) : renderWorkingDayBgGrid(p.k)};
  width: ${p => `${p.k * p.hoursNumber * HOUR_WIDTH + DAY_BORDER_WIDTH}px`};
`;
