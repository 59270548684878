import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Switch, message, Button } from 'antd';
import NProgress from 'nprogress';
import { text, basic, primary } from '../../../../constants/stylesConstants';
import H1 from '../../../common/MainHeader';
import { flexCenter, SwitchWrapper } from '../../../common/styledComponents';
import ConflictItem from './ConflictItem';
import { CancelBtn } from '../../../common/Button';
import { useRootContext } from '../../../../context';
import {
  createRecurrence,
  updateRecurrence,
} from '../../../../context/actions';
import { coflictResolvingError } from '../../../../constants/errorsMsgs';
import { recurrenceSuccesMsg } from '../../../../constants/successMsgs';

const Conflict = ({
  conflicts,
  regectedPayload,
  close,
  setConflicts,
  hideCommon,
  recurrenceId,
}) => {
  const { dispatch } = useRootContext();
  const [accepted, setAccepted] = useState(new Set());
  const [declined, setDeclined] = useState(new Set(conflicts));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const accept = conflict => {
    if (declined.delete(conflict)) setDeclined(new Set(declined));
    setAccepted(new Set(accepted.add(conflict)));
  };

  const decline = conflict => {
    if (accepted.delete(conflict)) setAccepted(new Set(accepted));
    setDeclined(new Set(declined.add(conflict)));
  };

  const acceptAll = useCallback(() => {
    setAccepted(new Set(conflicts));
    setDeclined(new Set());
  }, []);

  const declineAll = useCallback(() => {
    setAccepted(new Set());
    setDeclined(new Set(conflicts));
  }, []);

  const toggle = useCallback(checked => {
    if (checked) acceptAll();
    else declineAll();
  }, []);

  const submit = async () => {
    NProgress.start();
    NProgress.set(0.7);

    setIsSubmitting(true);

    const acceptedIndexes = [];
    const declinedIndexes = [];

    declined.forEach(conflict =>
      declinedIndexes.push(conflicts.indexOf(conflict))
    );

    accepted.forEach(conflict =>
      acceptedIndexes.push(conflicts.indexOf(conflict))
    );

    const newPayload = {
      ...regectedPayload,
      accept_conflicts: acceptedIndexes,
      deny_conflicts: declinedIndexes,
    };

    const errors = recurrenceId
      ? await updateRecurrence(dispatch, recurrenceId, newPayload)
      : await createRecurrence(dispatch, newPayload);

    setIsSubmitting(false);
    NProgress.done();

    if (errors) {
      message.warning(coflictResolvingError, 5);
      setConflicts(Object.values(errors));
    } else {
      message.success(recurrenceSuccesMsg);
      setTimeout(() => hideCommon(), 1000);
    }
  };

  return (
    <Container>
      <Title>
        <H1 type="modal">Oops, some conflicts</H1>
      </Title>
      <Content>
        {conflicts.map(conflict => (
          <ConflictItem
            key={conflict.existing_activity.start_point}
            conflict={conflict}
            accept={accept}
            decline={decline}
            checked={accepted.has(conflict)}
            isSubmitting={isSubmitting}
          />
        ))}
      </Content>
      <SwitchWrapper>
        <span>Decline all</span>{' '}
        <Switch onChange={toggle} disabled={isSubmitting} />
        <span>Accept all</span>
      </SwitchWrapper>
      <JustifyCenter>
        <CancelBtn onClick={close} disabled={isSubmitting}>
          Cancel
        </CancelBtn>
        <Button type="primary" disabled={isSubmitting} onClick={submit}>
          Submit
        </Button>
      </JustifyCenter>
    </Container>
  );
};

export default Conflict;

const Container = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 460px;
  height: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
  border-radius: 10px 10px;
  background: ${basic};
  color: ${text};
  overflow: hidden;
  padding-bottom: 25px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${basic};
  height: 60px;
  background: ${primary};
`;

const Content = styled.div`
  margin: 20px 20px 10px;
  max-height: 500px;
  overflow-y: auto;
`;

const JustifyCenter = styled.div`
  ${flexCenter};
  & > button:first-of-type {
    margin-right: 10px;
  }
`;
