import styled from 'styled-components';
import { useState } from 'react';

import Modal from '../../ModalWindows/Modal';
import Employee from './Employee';
import EmployeeSearch from './EmployeeSearch';
import EmployeeDndModal from '../../ModalWindows/EmployeeDnD';
import { useRootContext } from '../../../context';
import { LEFT_PANEL_WIDTH } from '../../../constants/continuum';

export default function EmployeesColumn() {
  const {
    state: { employees },
  } = useRootContext();

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const openDnDModalHandler = () => setIsVisibleModal(true);

  const closeDnDModalHandler = () => setIsVisibleModal(false);

  return (
    <Container>
      <EmployeeSearch openModal={openDnDModalHandler} />

      <div>
        {employees.map(
          (
            {
              id,
              insertion,
              first_name: firstName,
              last_name: lastName,
              phone_number: phone,
              contract_type: contractType,
              email_address: email,
              residence_city: residenceCity,
              allowed_methods: methods,
              establishment_name: establishment,
            },
            index
          ) => (
            <Employee
              key={id}
              index={index}
              methods={methods}
              employeeId={id}
              phoneNumber={phone}
              emailAddress={email}
              contractType={contractType}
              name={`${firstName} ${insertion} ${lastName}`}
              info={
                residenceCity
                  ? `${establishment} - ${residenceCity}`
                  : establishment
              }
            />
          )
        )}
      </div>

      {isVisibleModal && (
        <Modal background="rgba(0,0,0, 0.8)" onHideModal={closeDnDModalHandler}>
          <EmployeeDndModal onHideModal={closeDnDModalHandler} />
        </Modal>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: sticky;
  left: 0;
  z-index: 100;
  flex-shrink: 0;
  width: ${LEFT_PANEL_WIDTH}px;
  height: fit-content;
  background-color: white;
`;
