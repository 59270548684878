import styled from 'styled-components';
import moment from 'moment';
import { Switch } from 'antd';
import {
  borderColor,
  primary,
  basic,
} from '../../../../constants/stylesConstants';
import { flexCenter, SwitchWrapper } from '../../../common/styledComponents';
import {
  DATE_FULL_STRING_FORMAT,
  DATE_TIME_FORMAT,
} from '../../../../constants/formats';

const ConflictItem = ({ accept, decline, checked, conflict, isSubmitting }) => {
  const toggle = flag => {
    if (flag) accept(conflict);
    else decline(conflict);
  };

  const {
    existing_activity: {
      title,
      description,
      end_point: end,
      start_point: start,
    },
  } = conflict;

  return (
    <Container>
      <Title>Conflict on {moment(start).format(DATE_FULL_STRING_FORMAT)}</Title>
      <Row>
        <Col>
          <FieldName>
            title<span>:</span>
          </FieldName>
          <FieldName>
            description<span>:</span>
          </FieldName>
          <FieldName>
            start<span>:</span>
          </FieldName>
          <FieldName>
            end<span>:</span>
          </FieldName>
        </Col>

        <ValuesCol>
          <FieldValue>{title}</FieldValue>
          <FieldValue>{description || 'No description'}</FieldValue>
          <FieldValue>{moment(start).format(DATE_TIME_FORMAT)}</FieldValue>
          <FieldValue>{moment(end).format(DATE_TIME_FORMAT)}</FieldValue>
        </ValuesCol>
      </Row>
      <SwitchWrapper>
        <span>Decline</span>{' '}
        <Switch onChange={toggle} checked={checked} disabled={isSubmitting} />
        <span>Accept</span>
      </SwitchWrapper>
    </Container>
  );
};

export default ConflictItem;

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${borderColor};
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  height: 40px;
  ${flexCenter}
  text-align: center;
  background-color: ${primary};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 14px;
  color: ${basic};
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const Col = styled.div`
  width: fit-content;
`;

const FieldName = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding-bottom: 10px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

const FieldValue = styled.div`
  padding-bottom: 10px;
  text-align: right;
  &:last-of-type {
    padding-bottom: 0;
  }
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ValuesCol = styled.div`
  overflow: hidden;
  padding-left: 5px;
`;
