import { Tabs } from 'antd';
import NewCustomer from './NewCustomer';
import CustomerSelector from './CustomerSelector';

const { TabPane } = Tabs;

export default ({
  exit,
  next,
  customers,
  pushNewCustomer,
  customerId,
  setCustomerId,
  isNewCustomer,
  setIsNewCustomer,
}) => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Select existing customer" key="1">
        <CustomerSelector
          exit={exit}
          next={next}
          customers={customers}
          customerId={customerId}
          setCustomerId={setCustomerId}
          isNewCustomer={isNewCustomer}
          setIsNewCustomer={setIsNewCustomer}
        />
      </TabPane>
      <TabPane tab="Create new customer" key="2">
        <NewCustomer
          exit={exit}
          next={next}
          setCustomerId={setCustomerId}
          pushNewCustomer={pushNewCustomer}
          isNewCustomer={isNewCustomer}
          setIsNewCustomer={setIsNewCustomer}
        />
      </TabPane>
    </Tabs>
  );
};
