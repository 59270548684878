import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';

const SpecificationsTable = ({
  exucationSpecifications,
  selectedSpecifications,
  setSelectedSpecifications,
  isEditingAllowed,
}) => {
  const toggleSpecification = e => {
    const copySelectedSpecifications = [...selectedSpecifications];
    if (e.target.checked) copySelectedSpecifications.push(e.target.value);
    else {
      const index = copySelectedSpecifications.findIndex(
        specification => specification.name === e.target.value.name
      );
      copySelectedSpecifications.splice(index, 1);
    }
    setSelectedSpecifications(copySelectedSpecifications);
  };

  return (
    <>
      <Container>
        <Sticky>
          <TableHeader>
            <EmptyHeadColumn>&#160;</EmptyHeadColumn>
            <MethodColumn>Method</MethodColumn>
            <CommonCol>Executive Norm</CommonCol>
            <CommonCol>Acceptance Norm</CommonCol>
          </TableHeader>
        </Sticky>

        <div>
          {exucationSpecifications.map(
            ({
              method_id: methodId,
              method_name: methodName,
              execution_norm_name: eNormName,
              execution_configuration_id: normConfigId,
              acceptance_configuration_id: acceptanceConfigId,
              execution_procedure_name: eProcedureName,
              acceptance_norm_name: aNormName,
              acceptance_procedure_name: aProcedureName,
            }) => {
              return (
                <Row key={methodId}>
                  <EmptyHeadColumn>
                    <Checkbox
                      disabled={!isEditingAllowed}
                      onChange={toggleSpecification}
                      value={{
                        name: methodName,
                        specifications: {
                          execution_norm_configuration_id: normConfigId,
                          acceptance_norm_configuration_id: acceptanceConfigId,
                        },
                      }}
                    />
                  </EmptyHeadColumn>
                  <ColumnName>{methodName}</ColumnName>
                  <ColumnGroupWrapper>
                    <GroupColumn>
                      <Col>
                        <BoldText>{eNormName} </BoldText>
                      </Col>
                      <Col>
                        <BoldText>{aNormName}</BoldText>
                      </Col>
                    </GroupColumn>
                    <GroupColumn>
                      <Col>
                        <BoldText>Procedure:</BoldText>
                        {eProcedureName}
                      </Col>
                      <Col>
                        <BoldText>Procedure:</BoldText>
                        {aProcedureName}
                      </Col>
                    </GroupColumn>
                  </ColumnGroupWrapper>
                </Row>
              );
            }
          )}
        </div>
      </Container>
    </>
  );
};

export default SpecificationsTable;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1.5px solid #e9e9e9;
`;

const TableHeader = styled.div`
  display: flex;
  height: 50px;
  font-weight: 500;
  border: 1.5px solid #e9e9e9;
  background: #fafafa;
`;

const Col = styled.div`
  font-size: 17px;
  display: flex;
  flex-basis: 35%;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-right: 1.5px solid #e9e9e9;
  &:last-child {
    border-right: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-grow: 1;
  border-left: 1.5px solid #e9e9e9;
  border-bottom: 1.5px solid #e9e9e9;
  &:last-child {
    border-bottom: none;
  }
`;

const ColumnName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-basis: 25%;
  border-right: 1.5px solid #e9e9e9;
`;

const BoldText = styled.span`
  text-align: center;
  font-weight: 500;
`;

const GroupColumn = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid #e9e9e9;
  &:last-child {
    border-bottom: none;
  }
`;

const commonColumnStyles = css`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyHeadColumn = styled.div`
  ${commonColumnStyles};
  flex-basis: 5%;
  height: auto;
  border-right: 1.5px solid #e9e9e9;
  background: #fafafa;
`;

const MethodColumn = styled.div`
  ${commonColumnStyles};
  flex-basis: 25%;
  border-right: 1.5px solid #e9e9e9;
`;

const CommonCol = styled.div`
  ${commonColumnStyles};
  flex-basis: 35%;
  border-right: 1.5px solid #e9e9e9;
  &:last-child {
    border-right: none;
  }
`;
const ColumnGroupWrapper = styled.div`
  flex-basis: 70%;
  flex-grow: 1;
  flex-shrink: 0;
  border-right: 1.5px solid #e9e9e9;
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 100;
`;
