import GMS from '../context/globalMutableStore';
import { DAY_TITLE_HEIGHT, MIN_WIDTH } from '../constants/continuum';
import {
  getTimeX,
  getTimeW,
  getViewX,
  getViewXW,
  getViewMaxW,
  getMiddleY,
  getDiscreteY,
  getDiscreteTimeX,
  transformYtoIndex,
  transformXtoMoment,
  transformXWtoMoments,
  getDiscreteRightResizedXW,
} from './transfomation';
import { zDrag, dragBoxShadow } from '../constants/stylesConstants';
import {
  isSectorClear,
  handleOverlap,
  resetStyles,
  updateRowConflicts,
} from './overlaping';
import scroll from './scroll';
import {
  isAllowed,
  noPermissionForAction,
  noPermissionForResize,
} from '../utils/validation';
import { showErrorNotification } from '../utils/errorHandlers';
import { noRights } from '../constants/errorsMsgs';

export const detectEdge = (e, deltaX = 4) => {
  const { x, width } = e.target.getBoundingClientRect();
  if (width < 15) {
    if (e.clientX - x < 1.5) return 'left';
    if (x + width - e.clientX < 1.5) return 'right';
    return null;
  }
  if (e.clientX - x < deltaX) return 'left';
  if (x + width - e.clientX < deltaX) return 'right';
  return null;
};

export const getShift = (e, self, planeShift = { x: 0, y: 0 }) => {
  const { x, y } = self.getBoundingClientRect();
  return {
    x: e.clientX - x + planeShift.x,
    y: e.clientY - y + planeShift.y,
  };
};

// DRAG-AND-DROP

export const addDragStyles = self => {
  self.style.opacity = 0.8;
  self.style.zIndex = zDrag;
  self.style.cursor = 'grabbing';
  self.style.boxShadow = dragBoxShadow;

  document.body.style.cursor = 'grabbing';
};

export const removeDragStyles = self => {
  self.style.cursor = null;
  self.style.zIndex = null;
  self.style.opacity = null;
  self.style.boxShadow = null;

  document.body.style.cursor = null;
};

const drag = (e, self, board, shift) => {
  const x = e.clientX + board.scrollLeft - shift.x;
  let y = e.clientY + board.scrollTop - shift.y;
  if (y < DAY_TITLE_HEIGHT) y = DAY_TITLE_HEIGHT;

  self.style.left = `${x}px`;
  self.style.top = `${y}px`;
};

const dragSensitively = ({
  e,
  self,
  colW,
  board,
  shift,
  timeW,
  stepW,
  borderW,
}) => {
  const x = e.clientX + board.scrollLeft - shift.x;
  const timeX = getTimeX(x, colW, borderW);
  const { viewW } = getViewXW(timeX, timeW, stepW, colW, borderW);

  let y = e.clientY + board.scrollTop - shift.y;
  if (y < DAY_TITLE_HEIGHT) y = DAY_TITLE_HEIGHT;

  self.style.top = `${y}px`;
  self.style.left = `${x}px`;
  self.style.width = `${viewW}px`;
};

export const drop = ({
  e,
  self,
  board,
  shift,
  timeW,
  employees,
  employeeId,
  initStyles,
  onDropCallback,
  dimensions: {
    rowH,
    colW,
    cellW,
    stepW,
    blockH,
    borderW,
    topPadding,
    planeShift,
  },
  allowedEmployees,
  isUpdateAll,
}) => {
  removeDragStyles(self);

  if (e.clientY < planeShift.y + DAY_TITLE_HEIGHT)
    return handleOverlap(self, initStyles);

  const y = e.clientY + board.scrollTop - shift.y;
  const x = e.clientX + board.scrollLeft - shift.x;
  const discY = getMiddleY(y, blockH, rowH, topPadding);
  const discTimeX = getDiscreteTimeX(x, stepW, colW, borderW);
  const { viewX, viewW } = getViewXW(discTimeX, timeW, stepW, colW, borderW);
  const employeeIndex = transformYtoIndex(discY, rowH);

  self.style.top = `${discY}px`;
  self.style.left = `${viewX}px`;
  self.style.width = `${viewW}px`;

  if (
    noPermissionForAction(
      isUpdateAll,
      allowedEmployees,
      employees,
      employeeIndex
    )
  )
    return handleOverlap(self, initStyles);

  if (
    !employees[employeeIndex] ||
    !isSectorClear(self, employees[employeeIndex].id)
  ) {
    return handleOverlap(self, initStyles);
  }

  setTimeout(async () => {
    const { start, end } = transformXWtoMoments(
      GMS.zeroDate,
      discTimeX,
      timeW,
      cellW
    );

    const success = await onDropCallback({ start, end, employeeIndex });

    if (success) updateRowConflicts(employeeId);
    else handleOverlap(self, initStyles);
  });

  return null;
};

export const dragAndDrop = ({
  eDown,
  board,
  zeroDate,
  employees,
  employeeId,
  dimensions,
  isUpdateAll,
  clickDuration,
  onDropCallback,
  onClickCallback,
  allowedEmployees,
}) => {
  if (eDown.button === 0) {
    const keyDownTime = performance.now();
    const self = eDown.target;
    const { planeShift, stepW, colW, borderW, scrollSpeed } = dimensions;

    const shift = getShift(eDown, self, planeShift);

    const x = eDown.clientX + board.scrollLeft - shift.x;

    const timeW = getTimeW(x, self.offsetWidth, colW, borderW);

    const initStyles = {
      left: self.style.left,
      top: self.style.top,
      width: self.style.width,
    };

    addDragStyles(self);

    const handleMouseMove = eMove => {
      scroll({
        eMove,
        board,
        limit: 1,
        planeShift,
        speed: scrollSpeed,
        callback: () => {
          drag(eMove, self, board, shift);
        },
      });

      dragSensitively({
        e: eMove,
        self,
        board,
        shift,
        timeW,
        stepW,
        colW,
        borderW,
      });
    };

    const handleMouseUp = eUp => {
      GMS.scrollDirections = [];
      GMS.activeId = null;

      if (performance.now() - keyDownTime < clickDuration) {
        removeDragStyles(self);
        resetStyles(self, initStyles);
        onClickCallback(eUp);
      } else {
        drop({
          e: eUp,
          self,
          board,
          shift,
          timeW,
          zeroDate,
          employees,
          employeeId,
          initStyles,
          dimensions,
          isUpdateAll,
          onDropCallback,
          allowedEmployees,
        });
      }

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }
};

// RESIZING

const addResizeStyles = self => {
  self.style.cursor = 'ew-resize';
  document.body.style.cursor = 'ew-resize';
};

const removeResizeStyles = self => {
  self.style.cursor = null;
  document.body.style.cursor = null;
};

const changeWidthFromRight = ({
  e,
  self,
  board,
  minW,
  maxW,
  initW,
  initPageX,
}) => {
  const nextW = initW + e.clientX + board.scrollLeft - initPageX;
  if (nextW < minW) self.style.width = `${minW}px`;
  else if (nextW > maxW) self.style.width = `${maxW}px`;
  else self.style.width = `${nextW}px`;
};

const changeWidthFromLeft = ({
  e,
  self,
  board,
  initX,
  initW,
  minW,
  maxW,
  planeShift,
}) => {
  const pageX = e.clientX + board.scrollLeft - planeShift.x;
  const nextW = initW + initX - pageX;

  if (nextW > maxW) {
    self.style.width = `${maxW}px`;
    self.style.left = `${initX + initW - maxW}px`;
  } else if (nextW >= minW) {
    self.style.width = `${nextW}px`;
    self.style.left = `${pageX}px`;
  }
};

const finalizeRightResize = (
  self,
  board,
  { stepW, colW, borderW, planeShift }
) => {
  const { left, width } = self.getBoundingClientRect();
  const x = left + board.scrollLeft - planeShift.x;
  const { viewW, timeW, timeX } = getDiscreteRightResizedXW(
    x,
    width,
    stepW,
    colW,
    borderW
  );

  self.style.width = `${viewW}px`;
  return { timeX, timeW, timeRightX: timeX + timeW };
};

const finalizeLeftResize = (
  self,
  board,
  { stepW, colW, borderW, planeShift }
) => {
  const { left, right } = self.getBoundingClientRect();
  const x = left + board.scrollLeft - planeShift.x;
  const rightX = right + board.scrollLeft - planeShift.x;

  const discTimeX = getDiscreteTimeX(x, stepW, colW, borderW);
  const rightTimeX = getTimeX(rightX, colW, borderW);
  const discTimeW = rightTimeX - discTimeX;

  const viewX = getViewX(discTimeX, colW, borderW);
  const viewW = rightX - viewX;

  self.style.left = `${viewX}px`;
  self.style.width = `${viewW}px`;

  return { timeX: discTimeX, timeW: discTimeW };
};

export const resize = ({
  eDown,
  edge,
  board,
  callback,
  zeroDate,
  employeeId,
  dimensions,
  isUpdateAll,
  allowedEmployees,
}) => {
  if (eDown.button === 0) {
    const self = eDown.target;
    const {
      maxW,
      colW,
      cellW,
      borderW,
      planeShift,
      scrollLimit,
      scrollSpeed,
    } = dimensions;
    const { left, right, width: initW } = self.getBoundingClientRect();
    const initStyles = {
      left: self.style.left,
      top: self.style.top,
      width: self.style.width,
    };

    if (noPermissionForResize(isUpdateAll, allowedEmployees, employeeId))
      return null;

    addResizeStyles(self);

    if (edge === 'right') {
      const initPageX = eDown.clientX + board.scrollLeft;
      const viewX = left + board.scrollLeft - planeShift.x;
      const viewMaxW = getViewMaxW(edge, viewX, maxW, colW, borderW);

      const onMouseMove = eMove => {
        changeWidthFromRight({
          e: eMove,
          self,
          board,
          initW,
          initPageX,
          minW: MIN_WIDTH,
          maxW: viewMaxW,
        });
        scroll({
          eMove,
          board,
          planeShift,
          limit: scrollLimit,
          speed: scrollSpeed,
          callback: () => {
            changeWidthFromRight({
              e: eMove,
              self,
              board,
              initW,
              initPageX,
              minW: MIN_WIDTH,
              maxW: viewMaxW,
            });
          },
        });
      };

      const onMouseUp = () => {
        removeResizeStyles(self);
        GMS.scrollDirections = [];

        const { timeRightX } = finalizeRightResize(self, board, dimensions);

        if (isSectorClear(self, employeeId)) {
          setTimeout(async () => {
            const newPoint = transformXtoMoment(zeroDate, timeRightX, cellW);
            const success = await callback(newPoint);
            if (success) updateRowConflicts(employeeId);
            else handleOverlap(self, initStyles);
          });
        } else {
          handleOverlap(self, initStyles);
        }

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    } else {
      const initX = left + board.scrollLeft - planeShift.x;
      const rightVieWX = right + board.scrollLeft - planeShift.x;
      const viewMaxW = getViewMaxW(edge, rightVieWX, maxW, colW, borderW);

      const onMouseMove = eMove => {
        changeWidthFromLeft({
          e: eMove,
          self,
          board,
          initX,
          initW,
          planeShift,
          minW: MIN_WIDTH,
          maxW: viewMaxW,
        });
        scroll({
          eMove,
          board,
          planeShift,
          limit: scrollLimit,
          speed: scrollSpeed,
          callback: () => {
            changeWidthFromLeft({
              e: eMove,
              self,
              board,
              initX,
              initW,
              planeShift,
              minW: MIN_WIDTH,
              maxW: viewMaxW,
            });
          },
        });
      };

      const onMouseUp = () => {
        removeResizeStyles(self);
        GMS.scrollDirections = [];

        const { timeX } = finalizeLeftResize(self, board, dimensions);

        if (isSectorClear(self, employeeId)) {
          setTimeout(async () => {
            const newPoint = transformXtoMoment(zeroDate, timeX, cellW);
            const success = await callback(newPoint);
            if (success) updateRowConflicts(employeeId);
            else handleOverlap(self, initStyles);
          });
        } else {
          handleOverlap(self, initStyles);
        }

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  }

  return null;
};

// CREATION

const createDiv = (parent, x, y, w, h) => {
  const div = document.createElement('div');

  div.style.position = 'absolute';
  div.style.left = `${x}px`;
  div.style.top = `${y}px`;
  div.style.width = `${w}px`;
  div.style.height = `${h}px`;
  div.style.background = 'darkgrey';

  return parent.appendChild(div);
};

export const addTask = ({
  eDown,
  board,
  zeroDate,
  callback,
  employees,
  dimensions,
  allowedEmployees,
  isUpdateAll,
  grid,
}) => {
  const {
    rowH,
    colW,
    maxW,
    cellW,
    stepW,
    blockH,
    borderW,
    topPadding,
    planeShift,
    scrollLimit,
    scrollSpeed,
  } = dimensions;
  if (
    eDown.button === 0 &&
    eDown.clientY > planeShift.y + DAY_TITLE_HEIGHT &&
    eDown.clientY < planeShift.y + grid.offsetHeight
  ) {
    const parent = eDown.target;
    const initPageX = eDown.clientX + board.scrollLeft;
    const x = initPageX - planeShift.x;
    const y = eDown.clientY + board.scrollTop - planeShift.y;
    const discTimeX = getDiscreteTimeX(x, stepW, colW, borderW);
    const initDiscY = getDiscreteY(y, rowH, topPadding);
    const viewX = getViewX(discTimeX, colW, borderW);
    const div = createDiv(parent, viewX, initDiscY, 1, blockH);
    const viewMaxW = getViewMaxW(null, discTimeX, maxW, colW, borderW);

    const onMouseMove = eMove => {
      changeWidthFromRight({
        e: eMove,
        board,
        minW: 1,
        initW: 1,
        initPageX,
        self: div,
        maxW: viewMaxW,
      });
      scroll({
        eMove,
        board,
        planeShift,
        limit: scrollLimit,
        speed: scrollSpeed,
        callback: () => {
          changeWidthFromRight({
            e: eMove,
            board,
            minW: 1,
            initW: 1,
            initPageX,
            self: div,
            maxW: viewMaxW,
          });
        },
      });
    };

    const onMouseUp = () => {
      GMS.scrollDirections = [];
      if (div.offsetWidth > MIN_WIDTH) {
        const employeeIndex = transformYtoIndex(initDiscY, rowH);
        const { timeX, timeW } = finalizeRightResize(div, board, dimensions);

        if (isSectorClear(div, employees[employeeIndex].id)) {
          setTimeout(async () => {
            const { start, end } = transformXWtoMoments(
              zeroDate,
              timeX,
              timeW,
              cellW
            );
            if (
              isUpdateAll ||
              !allowedEmployees.length ||
              isAllowed(allowedEmployees, employees, employeeIndex)
            ) {
              await callback({ start, end, employeeIndex });
              parent.removeChild(div);
            } else {
              showErrorNotification(noRights);
              handleOverlap(div);
              setTimeout(() => parent.removeChild(div), 200);
            }
          });
        } else {
          handleOverlap(div);
          setTimeout(() => parent.removeChild(div), 200);
        }
      } else {
        parent.removeChild(div);
      }

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }
};
