import { addDragStyles, removeDragStyles } from '../interaction';
import { primary } from '../../constants/stylesConstants';
import { DAY_TITLE_HEIGHT } from '../../constants/continuum';
import {
  getMiddleY,
  getDiscreteTimeX,
  getViewXW,
  transformYtoIndex,
  transformXWtoMoments,
} from '../transfomation';
import { isSectorClear } from '../overlaping';
import globalMutableStore from '../../context/globalMutableStore';
import { noPermissionForAction } from '../../utils/validation';

const moveOnViewport = (e, self, shift) => {
  const x = e.clientX - shift.x;
  const y = e.clientY - shift.y;

  self.style.left = `${x}px`;
  self.style.top = `${y}px`;
};

const makeSnapshot = (e, width, height, shift) => {
  const x = e.clientX - shift.x;
  const y = e.clientY - shift.y;
  const div = document.createElement('div');

  div.style.position = 'fixed';
  div.style.left = `${x}px`;
  div.style.top = `${y}px`;
  div.style.width = `${width}px`;
  div.style.height = `${height}px`;
  div.style.background = primary;
  div.style.borderRadius = '10px';

  return div;
};

const showConfilct = div => {
  div.classList.add('overlap');
  setTimeout(() => div.remove(), 200);
};

const dropOnViewport = ({
  e,
  self,
  board,
  shift,
  employees,
  onDropCallback,
  dimensions: {
    rowH,
    colW,
    cellW,
    stepW,
    blockH,
    borderW,
    workOrderW,
    topPadding,
    planeShift,
  },
  allowedEmployees,
  isUpdateAll,
}) => {
  if (e.clientY < planeShift.y + DAY_TITLE_HEIGHT) return showConfilct(self);

  const y = e.clientY + board.scrollTop - shift.y - planeShift.y;
  const x = e.clientX + board.scrollLeft - shift.x - planeShift.x;
  const discY = getMiddleY(y, blockH, rowH, topPadding);
  const discTimeX = getDiscreteTimeX(x, stepW, colW, borderW);
  const { viewX, viewW } = getViewXW(
    discTimeX,
    workOrderW,
    stepW,
    colW,
    borderW
  );
  const employeeIndex = transformYtoIndex(discY, rowH);

  self.style.top = `${discY}px`;
  self.style.left = `${viewX}px`;
  self.style.width = `${viewW}px`;

  if (
    noPermissionForAction(
      isUpdateAll,
      allowedEmployees,
      employees,
      employeeIndex
    )
  )
    return showConfilct(self);

  if (
    !employees[employeeIndex] ||
    !isSectorClear(self, employees[employeeIndex].id)
  ) {
    return showConfilct(self);
  }

  setTimeout(async () => {
    const { start, end } = transformXWtoMoments(
      globalMutableStore.zeroDate,
      discTimeX,
      workOrderW,
      cellW
    );
    const success = await onDropCallback({ start, end, employeeIndex });
    if (success) self.remove();
    else showConfilct(self);
  });

  return null;
};

const addOriginStyles = origin => {
  origin.style.opacity = '0.5';
};

const removeOriginStyles = origin => {
  origin.style.opacity = '';
};

const handleMouseDown = (e, origin, dimensions) => {
  const { blockH, workOrderW } = dimensions;
  const shift = { x: workOrderW / 2, y: blockH / 2 };
  const self = makeSnapshot(e, workOrderW, blockH, shift);

  addDragStyles(self);
  addOriginStyles(origin);
  document.body.append(self);

  return { self, shift };
};

const checkDestination = (e, self, ancestor) => {
  self.remove();
  const destination = document.elementFromPoint(e.clientX, e.clientY);
  document.body.append(self);
  return ancestor.contains(destination);
};

const planWorkOrder = ({
  e: eDown,
  grid,
  board,
  origin,
  employees,
  dimensions,
  onDropCallback,
  allowedEmployees,
  isUpdateAll,
}) => {
  const { self, shift } = handleMouseDown(eDown, origin, dimensions);

  const handleMouseMove = eMove => moveOnViewport(eMove, self, shift);
  const handleMouseUp = eUp => {
    removeDragStyles(self);
    removeOriginStyles(origin);

    if (checkDestination(eUp, self, grid)) {
      dropOnViewport({
        e: eUp,
        self,
        board,
        shift,
        employees,
        dimensions,
        onDropCallback,
        allowedEmployees,
        isUpdateAll,
      });
    } else {
      showConfilct(self);
    }

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  document.addEventListener('mousemove', handleMouseMove);
  document.addEventListener('mouseup', handleMouseUp);
};

export default planWorkOrder;
