import styled from 'styled-components';

import { getHoursArray } from './helpers';
import { useRootContext } from '../../../context';
import { renderVerticalLines } from '../../../utils/gradients';

const HoursList = ({ hoursNumber = 24 }) => {
  const {
    state: { scale },
  } = useRootContext();

  return (
    <HoursListContainer k={scale}>
      {getHoursArray(hoursNumber)}
    </HoursListContainer>
  );
};

export default HoursList;

const HoursListContainer = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: justify;
  font-size: ${p => `${13 * p.k}px`};
  word-spacing: ${p => `${5.2 * p.k}px`};
  padding-left: ${p => `${3.2 * p.k}px`};
  background-image: ${p => renderVerticalLines(p.k)};
`;
