import React from 'react';

import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { Tag, Checkbox } from 'antd';
import ScrollContainer from 'react-indiana-drag-scroll';

import { TiWarning } from 'react-icons/ti';

import {
  basic,
  border,
  primary,
  alternative,
} from '../../../../constants/stylesConstants';
import { ROW_HEIGHT } from '../../../../constants/continuum';
import EmployeePopup from './EmployeePopup';
import { useSyntScrollListener } from '../../../../utils/hooks';

const tagStyle = {
  padding: '0 4px',
  fontSize: '12px',
  marginRight: '2px',
  fontWeight: '700',
  lineHeight: '18px',
};

export default function Employee({
  name,
  info,
  isDnd,
  index,
  visible,
  methods,
  employeeId,
  phoneNumber,
  emailAddress,
  contractType,
  hideEmployee,
}) {
  const userNameRef = React.useRef(null);

  const [isChecked, setIsChecked] = React.useState(visible);
  const [isPopupInfoVisible, setIsPopupInfoOpen] = React.useState(false);

  const hidePopup = () => setIsPopupInfoOpen(false);

  React.useEffect(() => {
    if (isPopupInfoVisible) {
      document.addEventListener('syntscroll', hidePopup);
    } else {
      document.removeEventListener('syntscroll', hidePopup);
    }
    return () => document.removeEventListener('syntscroll', hidePopup);
  }, [isPopupInfoVisible]);

  React.useEffect(() => {
    setIsChecked(visible);
  }, [visible]);

  useSyntScrollListener(isPopupInfoVisible, hidePopup);

  useClickAway(userNameRef, e => {
    if (e.target.classList.contains('employee-popup-link')) {
      setTimeout(() => setIsPopupInfoOpen(false), 300);
    } else {
      setIsPopupInfoOpen(false);
    }
  });

  return (
    <Card index={index}>
      <Info>
        <FullName ref={userNameRef} onClick={() => setIsPopupInfoOpen(true)}>
          {name}
        </FullName>

        <AdditionalInfo>{info}</AdditionalInfo>

        {methods && (
          <ScrollContainer>
            {Object.keys(methods).map(method => (
              <Tag
                key={method}
                style={{
                  ...tagStyle,
                  color: methods[method] ? 'white' : 'black',
                  backgroundColor: methods[method] ? 'green' : 'white',
                }}
              >
                {method}
              </Tag>
            ))}
          </ScrollContainer>
        )}
      </Info>

      {contractType === 'contractor' && (
        <TiWarning
          style={{
            position: 'absolute',
            top: '3px',
            right: '5px',
            fontSize: '18px',
          }}
        />
      )}

      {isDnd && (
        <Absolute>
          <Checkbox
            checked={isChecked}
            onChange={e => {
              setIsChecked(!isChecked);
              hideEmployee(index, e.target.checked);
            }}
          />
        </Absolute>
      )}

      {isPopupInfoVisible && !isDnd && (
        <PopupWrapper>
          <EmployeePopup
            employeeId={employeeId}
            emailAddress={emailAddress}
            phoneNumber={phoneNumber}
          />
        </PopupWrapper>
      )}
    </Card>
  );
}

const PopupWrapper = styled.div`
  position: absolute;
  left: 220px;
  display: block;
  width: fit-content;
`;

const Card = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'index',
})`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${ROW_HEIGHT}px;
  padding: 0 10px;
  border-right: ${border};
  border-bottom: ${border};
  background: ${({ index }) => ((index + 1) % 2 ? basic : alternative)};

  &:last-child ${PopupWrapper} {
    top: -45px;
  }
`;

const Info = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  white-space: nowrap;
`;

const FullName = styled.p`
  color: ${primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const AdditionalInfo = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// const WarningIcon = styled(WarningSvg)`
//   position: absolute;
//   top: 3px;
//   right: 5px;
//   width: 16px;
//   height: auto;
// `;

const Absolute = styled.div`
  position: absolute;
  bottom: 3px;
  right: 5px;
`;
