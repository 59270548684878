import moment from 'moment';

export function getHoursArray(hoursNumber) {
  const hoursArray = new Array(24)
    .fill(0)
    .map((_, i) => ` ${`0${i}`.slice(-2)}`);

  if (hoursNumber === 23) hoursArray.splice(2, 1);
  if (hoursNumber === 25) hoursArray.splice(2, 0, ' 02');

  return hoursArray;
}

export function getDayHoursNumber(momentDate) {
  const todayIsDST = momentDate.isDST();
  const tomorrowIsDST = moment(momentDate)
    .add(1, 'days')
    .isDST();

  if (todayIsDST !== tomorrowIsDST && todayIsDST < tomorrowIsDST) return 23;
  if (todayIsDST !== tomorrowIsDST && todayIsDST > tomorrowIsDST) return 25;

  return 24;
}
