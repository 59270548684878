import React from 'react';

import { Select } from 'formik-antd';
import { ErrorMessage } from 'formik';

import API from '../../../../api';
import { SpecificError } from '../../../common/Messages';

const { Option } = Select;

export default function TagsSelector() {
  const [tags, setTags] = React.useState([]);
  const [isLoading, toggleLoading] = React.useState(false);

  const fetchTags = async () => {
    try {
      toggleLoading(true);

      const data = await API.getProjectTags();

      setTags(data);
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  return (
    <>
      <Select
        size="large"
        name="Tag"
        style={{ width: '100%' }}
        placeholder="Tag"
        disabled={isLoading}
        filterOption={false}
        notFoundContent={null}
        defaultActiveFirstOption={false}
      >
        {tags.map(tag => {
          return (
            <Option key={tag.id} value={tag.id}>
              {tag.name}
            </Option>
          );
        })}
      </Select>
      <SpecificError>
        <ErrorMessage name="Tag" />
      </SpecificError>
    </>
  );
}
