import * as Moment from 'moment';

import API from '../api';
import t from './actionsTypes';
import {
  handleServerError,
  showErrorNotification,
} from '../utils/errorHandlers';
import {
  employeesOrderError,
  activityTypesError,
  workOrdersListError,
} from '../constants/errorsMsgs';

export const getActivitiesAndEmployees = async (dispatch, params, onError) => {
  try {
    const data = await API.fetchActivitiesAndEmployees(params);

    dispatch({
      type: t.SAVE_ACTIVITIES_AND_EMPLOYEES,
      payload: data,
    });
  } catch (e) {
    handleServerError(e, onError);
  }
};

export const getActivities = async (dispatch, params, onError, isRightSide) => {
  try {
    const data = await API.fetchOnlyActivities(params);
    dispatch({
      type: t.FETCH_ACTIVITIES_BY_SCROLLING,
      payload: { ...data, isRightSide },
    });
  } catch (e) {
    handleServerError(e, onError);
  }
};

export const getUnplannedWorkOrders = async (dispatch, onError) => {
  try {
    const data = await API.fetchUnplannedWorkOrders();
    dispatch({
      type: t.FETCH_UNPLANNED_WORK_ORDERS,
      payload: data,
    });
  } catch (e) {
    handleServerError(e, onError);
  }
};

export const updateUnplWorkOrders = (dispatch, payload) =>
  dispatch({ type: t.UPDATE_UNPLANNED_WORK_ORDERS, payload });

export const searchEmployees = (dispatch, payload) =>
  dispatch({ type: t.SEARCH_EMPLOYEES, payload });

export const createActivity = async (dispatch, payload, onError) => {
  try {
    const data = await API.postActivity(payload);
    dispatch({ type: t.INSERT_ACTIVITY, payload: data });
    return data;
  } catch (e) {
    handleServerError(e, onError);
    return null;
  }
};

export const updateActivity = async (dispatch, payload, callback) => {
  try {
    const data = await API.postActivity(payload);
    dispatch({ type: t.UPDATE_ACTIVITY, payload: data });
    if (callback) callback();
    return data;
  } catch (e) {
    handleServerError(e);
    return null;
  }
};

export const removeActivity = async (
  dispatch,
  payload,
  callback,
  activities,
  visibleEmployees
) => {
  const matchedActivity = activities.find(({ id }) => id === payload);

  const dateFrom =
    matchedActivity.start_point || matchedActivity.recurrence_start;
  const dateTo = matchedActivity.end_point || matchedActivity.recurrence_end;

  const formattedStart = Moment(dateFrom).format('YYYY-MM-DD');
  const formattedEnd = Moment(dateTo).format('YYYY-MM-DD');

  const employeeIds = visibleEmployees.map(i => i.id);

  try {
    await API.deleteActivity(payload);

    dispatch({
      type: t.DELETE_ACTIVITY,
      payload,
    });

    const updatedSummary = await API.updateDaysSummary(
      formattedStart,
      formattedEnd,
      employeeIds
    );

    dispatch({
      type: t.UPDATE_DAYS_SUMMARY,
      payload: updatedSummary,
    });

    if (callback) callback();

    return true;
  } catch (e) {
    handleServerError(e);
    return false;
  }
};

export const removeEmployee = async (dispatch, payload, onError) => {
  try {
    await API.deleteEmployee(payload);
    dispatch({
      type: t.REMOVE_EMPLOYEE,
      payload,
    });

    return true;
  } catch (e) {
    handleServerError(e, onError);
    return false;
  }
};

export const removeAllActivities = async (dispatch, payload, onError) => {
  try {
    await API.deleteAllAcitivities(payload);
    dispatch({
      type: t.REMOVE_ALL_ACTIVITIES,
      payload,
    });
  } catch (e) {
    handleServerError(e, onError);
  }
};

export const addTodayRef = (dispatch, payload) =>
  dispatch({ type: t.ADD_TODAY_REF, payload });

export const addBoardRef = (dispatch, payload) =>
  dispatch({ type: t.ADD_BOARD_REF, payload });

export const changeCustomerFilter = (dispatch, payload) =>
  dispatch({ type: t.CHANGE_CUSTOMER_FILTER, payload });

export const changeLocationFilter = (dispatch, payload) =>
  dispatch({ type: t.CHANGE_LOCATION_FILTER, payload });

export const changeMethodFilter = (dispatch, payload) =>
  dispatch({ type: t.CHANGE_METHOD_FILTER, payload });

export const changeEstablishmentFilter = (dispatch, payload) =>
  dispatch({ type: t.CHANGE_ESTABLISHMENT_FILTER, payload });

export const searchUnplannedOrders = (dispatch, payload) =>
  dispatch({ type: t.SEARCH_UNPLANNED_ORDERS, payload });

export const changeWOCustomerFilter = (dispatch, payload) =>
  dispatch({ type: t.СHANGE_WO_CUSTOMER_FILTER, payload });

export const changeWOLocationFilter = (dispatch, payload) =>
  dispatch({ type: t.СHANGE_WO_LOCATION_FILTER, payload });

export const changeWOEstablishmentFilter = (dispatch, payload) =>
  dispatch({ type: t.CHANGE_WO_ESTABLISHMENT_FILTER, payload });

export const searchWorkOrders = (dispatch, payload) =>
  dispatch({ type: t.SEARCH_WORK_ORDERS, payload });

export const fetchOutsideDate = async (dispatch, params) => {
  try {
    const data = await API.fetchOnlyActivities(params);
    dispatch({
      type: t.FETCH_OUTSIDE_DATE,
      payload: data,
    });
  } catch (e) {
    handleServerError(e);
  }
};

export const updateEmployeesOrder = async (dispatch, payload) => {
  const { dates, checkeds, positionsMap } = payload;

  const startDate = dates[0].date;
  const endDate = dates[dates.length - 1].date;
  const employeeIds = checkeds.employees
    .filter(({ visible }) => visible)
    .map(({ id }) => id);

  try {
    await API.saveEmployeesOrder(positionsMap);

    dispatch({ type: t.UPDATE_EMPLOYEES_ORDER, payload: { ...checkeds } });

    if (employeeIds.length > 0) {
      const updatedSummary = await API.updateDaysSummary(
        startDate,
        endDate,
        employeeIds
      );

      dispatch({
        type: t.UPDATE_DAYS_SUMMARY,
        payload: updatedSummary,
      });
    } else {
      const zeroSummary = dates.map(data => ({
        date: data.date,
        toPlan: 0,
        planned: 0,
        invoiced: 0,
      }));

      dispatch({
        type: t.UPDATE_DAYS_SUMMARY,
        payload: zeroSummary,
      });
    }
  } catch (_) {
    showErrorNotification(employeesOrderError);
  }
};

export const getActivitiesTypes = async dispatch => {
  try {
    const payload = await API.fetchActivitiesTypes();
    dispatch({ type: t.GET_ACTIVITIES_TYPES, payload });
  } catch (_) {
    showErrorNotification(activityTypesError);
  }
};

export const getWorkOrders = async dispatch => {
  try {
    const payload = await API.fetchWorkOrders();
    dispatch({ type: t.GET_WORK_ORDERS, payload });
  } catch (_) {
    showErrorNotification(workOrdersListError);
  }
};

export const getWorkOrderFilters = (dispatch, payload) =>
  dispatch({ type: t.GET_WORK_ORDER_FILTERS, payload });

export const zoomIn = dispatch => dispatch({ type: t.ZOOM_IN });
export const zoomOut = dispatch => dispatch({ type: t.ZOOM_OUT });
export const resetScale = dispatch => dispatch({ type: t.RESET_SCALE });

export const createRecurrence = async (dispatch, pattern) => {
  try {
    const data = await API.createRecurrence(pattern);
    if (data && data.recurring_activity) {
      data.recurring_activity.work_order_id = pattern.work_order_id;
      dispatch({
        type: t.CREATE_RECURRING_ACTIVITY,
        payload: data.recurring_activity,
      });
    } else {
      handleServerError({});
    }
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data[0] &&
      e.response.status === 409
    ) {
      return e.response.data;
    }
    handleServerError(e);
  }
  return null;
};

export const updateRecurrence = async (dispatch, id, pattern) => {
  try {
    const data = await API.updateRecurrence(id, pattern);
    if (data && data.recurring_activity) {
      dispatch({
        type: t.UPDATE_RECURRING_ACTIVITY,
        payload: data.recurring_activity,
      });
    } else {
      handleServerError({});
    }
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data[0] &&
      e.response.status === 409
    ) {
      return e.response.data;
    }
    handleServerError(e);
  }
  return null;
};

export const deleteReccurence = async (dispatch, id) => {
  try {
    await API.deleteRecurrence(id);
    dispatch({ type: t.DELETE_RECURRING_ACTIVITY, payload: id });
  } catch (e) {
    handleServerError(e);
  }
};

export const setActiveEmployee = (dispatch, id) =>
  dispatch({ type: t.SET_ACTIVE_EMPLOYEE, payload: id });

export const removeUnplannedWorkOrder = (dispatch, id) =>
  dispatch({ type: t.REMOVE_UNPLANNED_WORK_ORDER, payload: id });

export const addFile = async (
  dispatch,
  formData,
  name,
  fileUid,
  fileType,
  onProgress,
  onSuccess
) => {
  try {
    dispatch({ type: t.SET_IS_FILE_LOADING, payload: true });
    const data = await API.uploadFile(formData, onProgress, onSuccess);
    dispatch({
      type: t.ADD_ATTACHED_FILE_ID,
      payload: { fileId: data.file_id, uid: fileUid, fileType, name },
    });
    dispatch({ type: t.SET_IS_FILE_LOADING, payload: false });
  } catch (e) {
    handleServerError(e);
  }
};

export const deleteFile = async (dispatch, id) => {
  try {
    const data = await API.deleteUploadFile(id);
    dispatch({ type: t.DELETE_ATTACHED_FILE_ID, payload: data.document_id });
  } catch (e) {
    handleServerError(e);
  }
};

export const deleteAllFiles = dispatch =>
  dispatch({ type: t.DELETE_ALL_FILES });
