import { useCallback, useState } from 'react';
import Modal from './Modal';
import SelectionModal from './SelectionModal';
import CommonActivityModal from './Common';
import Wizard from './Wizard';
import { useRootContext } from '../../context';
import { deleteAllFiles } from '../../context/actions';

export default ({ close, activityData, hideEventModal }) => {
  const { dispatch } = useRootContext();

  const [workOrder, setWorkOrder] = useState(null);
  const [isWizardShown, setIsWizardShown] = useState(false);
  const [isNewEventShown, setIsNewEventShown] = useState(true);
  const [isActivityShown, setIsActivityShown] = useState(false);

  const closeWizard = (id, title) => {
    deleteAllFiles(dispatch);
    setWorkOrder({ title, type: 2, workOrderId: id });
    setIsWizardShown(false);
  };

  const closeWizard2 = () => {
    deleteAllFiles(dispatch);
    setIsWizardShown(false);
    hideEventModal();
  };

  const openWizard = useCallback(() => {
    setIsWizardShown(true);
    setIsNewEventShown(false);
  }, []);

  const openActivityModal = useCallback(() => {
    setIsActivityShown(true);
    setIsNewEventShown(false);
  }, []);

  return (
    <>
      <Modal onHideModal={close}>
        {isNewEventShown && (
          <SelectionModal
            openActivityModal={openActivityModal}
            openWorkorderWizard={openWizard}
            close={close}
          />
        )}

        {isActivityShown && (
          <CommonActivityModal
            modalType="new"
            onHideModal={close}
            {...activityData}
          />
        )}

        {workOrder && (
          <CommonActivityModal
            modalType="new"
            hideEventType
            onHideModal={close}
            {...activityData}
            {...workOrder}
          />
        )}
      </Modal>

      {isWizardShown && (
        <Modal background="transparent">
          <Wizard
            activityData={activityData}
            employeeId={activityData.employeeId}
            exit={close}
            finish={closeWizard}
            finish2={closeWizard2}
          />
        </Modal>
      )}
    </>
  );
};
