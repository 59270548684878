import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import EmployeesTable from './EmployeesTable';
import SpecificationsTable from './SpecificationsTable';
import { useFetchData } from '../../../../utils/hooks';
import { CenterAntSpin } from '../../../common/Spinners';
import API from '../../../../api';
import RadiographicFormik from './RadiographicFormik';
import { FlexBeetwen, TwoBtnWrapper } from '../../../common/styledComponents';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/formats';
import {
  handleServerError,
  showSuccessNotification,
} from '../../../../utils/errorHandlers';
import { createActivity } from '../../../../context/actions';
import { useRootContext } from '../../../../context';
import { getServerUrl } from '../../../../utils/apiUrls';

const createRequestObj = step1Data => {
  return {
    ...step1Data,
  };
};

const serverUrl = getServerUrl();

const NewWorkOrder2 = ({
  exit,
  prev,
  activityData,
  step1Data,
  projectId,
  finish2,
}) => {
  const { dispatch } = useRootContext();
  const [isEditingAllowed, setIsEditingAllowed] = useState(true);
  const [isRedirect, setIsRedirect] = useState(false);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);
  const { employeeId, dates } = activityData;
  const [rows, setRows] = useState([
    {
      employeeId,
      dates,
    },
  ]);
  const [isLoading, isError, exucationSpecifications] = useFetchData(
    API.getExecutionSpecifications,
    projectId
  );
  console.log({ isError });
  const [workOrderData, setWorkOrderData] = useState(null);

  const viewButton =
    !selectedSpecifications.length ||
    !selectedSpecifications.some(
      specification => specification.name === 'Radiographic examination'
    );

  const createActivityReqObj = (row, workOrder) => {
    const { dates: d } = row;
    const { start, end } = d;
    const [startDate, startTime] = start.split(' ');
    const [endDate, endTime] = end.split(' ');

    return {
      employee_id: row.employeeId,
      type: 2,
      work_order_id: workOrder.workOrderId,
      title: workOrder.title,
      description: '',
      start_date: moment(startDate).format(DATE_FORMAT),
      start_time: moment(startTime, TIME_FORMAT).format(TIME_FORMAT),
      end_date: moment(endDate).format(DATE_FORMAT),
      end_time: moment(endTime, TIME_FORMAT).format(TIME_FORMAT),
    };
  };

  const handleSubmit = async values => {
    try {
      const data = await API.postWorkOrder(values);
      return data;
    } catch (e) {
      handleServerError(e);
      return null;
    }
  };

  const saveExucationSpecification = async (specifications, workOrderId) => {
    if (specifications.length) {
      const specificationsArray = specifications.map(
        specification => specification.specifications
      );
      const reqObject = {
        work_order_id: workOrderId,
        specifications: specificationsArray,
      };
      try {
        const data = await API.saveExucationSpecification(reqObject);
        return data;
      } catch (e) {
        handleServerError(e);
      }
    }
  };

  const createNewWorkOrder = async (workOrder, activity, exucations) => {
    if (workOrder) {
      const { work_order_id: workOrderId, work_order_title: title } = workOrder;
      activity = await createActivity(
        dispatch,
        createActivityReqObj(rows[0], { workOrderId, title })
      );
      if (selectedSpecifications.length && activity) {
        exucations = await saveExucationSpecification(
          selectedSpecifications,
          workOrderId
        );
      } else {
        exucations = false;
      }

      if (!activity) {
        setIsEditingAllowed(true);
        return null;
      }

      if (selectedSpecifications.length && !exucations) {
        setIsEditingAllowed(true);
        return null;
      }

      if (isRedirect)
        window.open(`${serverUrl}/work-orders/${workOrderId}/edit/`, '_blank');

      finish2();
    }

    if (workOrder) showSuccessNotification('Work Order successfully created');
    if (activity) showSuccessNotification('Tasks successfully created');
    if (exucations)
      showSuccessNotification('Exucation Specification successfully saved');
    setIsEditingAllowed(true);
  };

  if (isLoading) return <CenterAntSpin />;

  return (
    <Container>
      <Section>Planning</Section>
      <EmployeesTable
        isEditingAllowed={isEditingAllowed}
        rows={rows}
        setRows={setRows}
      />

      {exucationSpecifications.length ? (
        <>
          <Section>Execution Specification</Section>
          <SpecificationsTable
            prev={prev}
            exit={exit}
            rows={rows}
            selectedSpecifications={selectedSpecifications}
            isEditingAllowed={isEditingAllowed}
            setSelectedSpecifications={setSelectedSpecifications}
            exucationSpecifications={exucationSpecifications}
          />
        </>
      ) : null}

      {selectedSpecifications.find(
        specification => specification.name === 'Radiographic examination'
      ) && (
        <RadiographicFormik
          prev={prev}
          exit={exit}
          rows={rows}
          finish2={finish2}
          step1Data={step1Data}
          handleSubmit={handleSubmit}
          setIsEditingAllowed={setIsEditingAllowed}
          createActivityReqObj={createActivityReqObj}
          selectedSpecifications={selectedSpecifications}
          saveExucationSpecification={saveExucationSpecification}
        />
      )}

      {!exucationSpecifications.length && (
        <CheckboxWrapper>
          <SCheckbox
            value={isRedirect}
            onChange={e => setIsRedirect(e.target.checked)}
            disabled={!isEditingAllowed}
          >
            Add extra procedures or specefications?
          </SCheckbox>
        </CheckboxWrapper>
      )}
      {viewButton && (
        <StyledFlexBeetwen>
          <Button type="dashed" disabled={!isEditingAllowed} onClick={exit}>
            Exit
          </Button>
          <TwoBtnWrapper>
            <Button disabled={!isEditingAllowed} onClick={prev}>
              Back
            </Button>
            <button
              type="submit"
              className="ant-btn ant-btn-primary"
              onClick={async () => {
                let activity;
                let exucations;

                setIsEditingAllowed(false);
                if (!workOrderData) {
                  const workOrder = await handleSubmit(
                    createRequestObj(step1Data)
                  );
                  setWorkOrderData(workOrder);
                  createNewWorkOrder(workOrder, activity, exucations);
                } else createNewWorkOrder(workOrderData, activity, exucations);
              }}
              disabled={!isEditingAllowed}
            >
              Create Work Order
            </button>
          </TwoBtnWrapper>
        </StyledFlexBeetwen>
      )}
    </Container>
  );
};

export default NewWorkOrder2;

const Container = styled.div`
  padding: 20px;
`;
const Section = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 25px;
  margin-top: 15px;
`;

const SCheckbox = styled(Checkbox)`
  font-weight: 500;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 10px;
`;

const StyledFlexBeetwen = styled(FlexBeetwen)`
  padding-top: 30px;
`;
