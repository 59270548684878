import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce/lib';

import { CaretDownOutlined } from '@ant-design/icons';

import H1 from '../../common/MainHeader';
import { secondary } from '../../../constants/stylesConstants';
import SearchFilters from './SearchFilters';
import { useRootContext } from '../../../context';
import {
  searchUnplannedOrders,
  getUnplannedWorkOrders,
} from '../../../context/actions';

const { Search } = Input;

export default () => {
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);

  const onFiltersPress = () => {
    setIsFiltersOpen(prevState => !prevState);
  };

  const { dispatch } = useRootContext();

  const [debouncedInput] = useDebouncedCallback(query => {
    getUnplannedWorkOrders(dispatch);
    searchUnplannedOrders(dispatch, query.toLowerCase());
  }, 1000);

  return (
    <SearchPanel>
      <MarginBottom>
        <H1>UNPLANNED ORDERS</H1>
      </MarginBottom>

      <SearchInput>
        <MarginRight>
          <SearchAnt
            placeholder="Search"
            onChange={({ target }) => debouncedInput(target.value)}
          />
        </MarginRight>

        <OpenFilters onClick={onFiltersPress}>
          <MarginRight>
            <Text>Filters</Text>
          </MarginRight>

          <CaretDownOutlined />
        </OpenFilters>
      </SearchInput>
      {isFiltersOpen && <SearchFilters />}
    </SearchPanel>
  );
};

const SearchPanel = styled.div`
  background-color: ${secondary};
  font-size: 14px;
  padding: 15px 19px 22px 16px;
`;

const SearchInput = styled.div`
  display: flex;
  align-items: center;
`;

const MarginRight = styled.div`
  margin-right: 15px;
  width: 100%;
`;

const Text = styled.p``;

const MarginBottom = styled.div`
  margin-bottom: 5px;
`;

const OpenFilters = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SearchAnt = styled(Search)`
  max-width: 490px;
  width: 100%;
`;
