import styled from 'styled-components';
import { Select } from 'antd';
import { useRootContext } from '../../../context';
import {
  changeLocationFilter,
  changeCustomerFilter,
  changeMethodFilter,
  changeEstablishmentFilter,
  getUnplannedWorkOrders,
} from '../../../context/actions';

const { Option } = Select;

export default () => {
  const {
    state: {
      unplannedWorkOrdersLocations,
      unplannedWorkOrdersMethods,
      unplannedWorkOrdersCustomers,
      unplannedWorkOrdersEstablishments,
      locationFilters,
      customerFilters,
      methodFilters,
      establishmentFilters,
    },
    dispatch,
  } = useRootContext();

  const handleLocationChange = locations => {
    getUnplannedWorkOrders(dispatch);
    changeLocationFilter(dispatch, locations);
  };

  const handleCustomerChange = customers => {
    getUnplannedWorkOrders(dispatch);
    changeCustomerFilter(dispatch, customers);
  };

  const handleMethodChange = methods => {
    getUnplannedWorkOrders(dispatch);
    changeMethodFilter(dispatch, methods);
  };
  const handleEstablishmentChange = establishments => {
    getUnplannedWorkOrders(dispatch);
    changeEstablishmentFilter(dispatch, establishments);
  };

  return (
    <SearchFilters>
      <MarginBottom>
        <FilterCategory>Establishments</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Establishments"
          value={establishmentFilters}
          onChange={handleEstablishmentChange}
        >
          {unplannedWorkOrdersEstablishments.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>

      <MarginBottom>
        <FilterCategory>Methods</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Methods"
          value={methodFilters}
          onChange={handleMethodChange}
        >
          {unplannedWorkOrdersMethods.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>

      <MarginBottom>
        <FilterCategory>Work Location</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Work Location"
          value={locationFilters}
          onChange={handleLocationChange}
        >
          {unplannedWorkOrdersLocations.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>

      <MarginBottom>
        <FilterCategory>Customer</FilterCategory>
        <Select
          size="large"
          mode="multiple"
          placeholder="Customer"
          value={customerFilters}
          onChange={handleCustomerChange}
        >
          {unplannedWorkOrdersCustomers.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </MarginBottom>
    </SearchFilters>
  );
};

const SearchFilters = styled.div`
  margin-top: 28px;
`;

const MarginBottom = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }

  /* Interrupting default ant styles for Select */

  .ant-select-lg {
    width: 100%;
  }

  .ant-select-selection__choice {
    background: #ececec;
    border-radius: 3px;
  }

  .ant-select-selection__choice__remove {
    color: #b1b1b1;
    font-size: 14px;
  }

  .ant-select-selection__choice__content {
    color: #b1b1b1;
  }
`;

const FilterCategory = styled.p`
  margin-bottom: 5px;
`;
