import PulseLoader from 'react-spinners/PulseLoader';
import styled, { css } from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import { FaCity, FaFileContract } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Employee from '../../ResourcePlanning/EmployeesColumn/Employee';
import { useRootContext } from '../../../context';
import { updateEmployeesOrder } from '../../../context/actions';
import { basic, secondary, border } from '../../../constants/stylesConstants';
import { DeletePlanningBtn, SaveBtnWithIcon } from '../../common/Button';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const EmployeeDndModal = ({ onHideModal }) => {
  const {
    state: { dates, allEmployees },
    dispatch,
  } = useRootContext();

  const [isLoading, setIsLoading] = useState(false);
  const [localEmployees, setLocalEmployees] = useState([]);
  const [localPositionsMap, setLocalPositionsMap] = useState({});
  const [contractTypes, setContractTypes] = useState([]);
  const [selectedContractTypes, setSelectedContractTypes] = useState([]);
  const [establishmentNames, setEstablishmentNames] = useState([]);
  const [selectedEstablishments, setSelectedEstablishments] = useState([]);

  const collectUniqueNamesHandler = field => [
    ...new Set(allEmployees.map(i => i[field])),
  ];

  const updatePositionsHandler = (
    filteredEmployees,
    updatedContractsSelection,
    updatedEstablishmentsSelection
  ) => {
    const positionsMap = {};

    filteredEmployees.forEach(({ id, visible }, i) => {
      positionsMap[id] = { sort_order: i, visible: !!visible };
    });

    setLocalPositionsMap({
      employees: positionsMap,
      establishment_names: establishmentNames.reduce((acc, curr) => {
        acc[curr] = updatedEstablishmentsSelection.includes(curr);
        return acc;
      }, {}),
    });
  };

  useEffect(() => {
    if (allEmployees.length) {
      const contracts = collectUniqueNamesHandler('contract_type');
      const establishments = collectUniqueNamesHandler('establishment_name');

      setContractTypes(contracts);
      setEstablishmentNames(establishments);

      setLocalEmployees(allEmployees.map(i => ({ ...i })));
    }
  }, [allEmployees]);

  const filtersHandler = (field, value, isSelected) => {
    const updatedEmployees = [...localEmployees.map(i => ({ ...i }))];

    const updatedContractsSelection = [...selectedContractTypes];
    const updatedEstablishmentsSelection = [...selectedEstablishments];

    switch (field) {
      case 'contract_type':
        if (isSelected) {
          updatedContractsSelection.push(value);
        } else {
          updatedContractsSelection.splice(
            updatedContractsSelection.indexOf(value),
            1
          );
        }
        break;
      case 'establishment_name':
        if (isSelected) {
          updatedEstablishmentsSelection.push(value);
        } else {
          updatedEstablishmentsSelection.splice(
            updatedEstablishmentsSelection.indexOf(value),
            1
          );
        }
        break;
      default:
    }

    const isContractsSelection = updatedContractsSelection.length > 0;
    const isEstablishmentsSelection = updatedEstablishmentsSelection.length > 0;
    const isAllSelection = isContractsSelection && isEstablishmentsSelection;

    updatedEmployees.forEach(employee => {
      const { contract_type, establishment_name } = employee;

      employee.visible = false;

      if (isAllSelection) {
        employee.visible =
          updatedContractsSelection.includes(contract_type) &&
          updatedEstablishmentsSelection.includes(establishment_name);
      } else if (isContractsSelection) {
        employee.visible = updatedContractsSelection.includes(contract_type);
      } else if (isEstablishmentsSelection) {
        employee.visible = updatedEstablishmentsSelection.includes(
          establishment_name
        );
      }
    });

    updatePositionsHandler(
      updatedEmployees,
      updatedContractsSelection,
      updatedEstablishmentsSelection
    );

    setSelectedContractTypes(updatedContractsSelection);
    setSelectedEstablishments(updatedEstablishmentsSelection);

    setLocalEmployees(updatedEmployees);
  };

  const singleEmployeeSelectionHandler = (index, isSelected) => {
    const updatedPositionsMap = {};

    const updatedEmployees = [...localEmployees.map(i => ({ ...i }))];

    updatedEmployees[index].visible = isSelected;

    updatedEmployees.forEach(({ id, visible }, i) => {
      updatedPositionsMap[id] = {
        sort_order: i,
        visible: !!visible,
      };
    });

    updatePositionsHandler(
      updatedEmployees,
      selectedContractTypes,
      selectedEstablishments
    );

    setLocalEmployees(updatedEmployees);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const updatedPositionsMap = {};

    const updatedEmployees = reorder(
      localEmployees,
      result.source.index,
      result.destination.index
    );

    updatedEmployees.forEach(({ id, visible }, i) => {
      updatedPositionsMap[id] = { sort_order: i, visible: !!visible };
    });

    setLocalPositionsMap({
      employees: updatedPositionsMap,
      establishment_names: establishmentNames.reduce((acc, curr) => {
        acc[curr] = selectedEstablishments.includes(curr);
        return acc;
      }, {}),
    });

    setLocalEmployees(updatedEmployees);
  };

  const onSaveChanges = async () => {
    setIsLoading(true);

    await updateEmployeesOrder(dispatch, {
      dates,
      checkeds: {
        employees: localEmployees,
      },
      positionsMap: localPositionsMap,
    });

    onHideModal();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <FlexEndActions>
          <HoverCancelPlanningBtn onClick={onHideModal}>
            Cancel
          </HoverCancelPlanningBtn>

          <SaveBtnWithIcon
            disabled={
              JSON.stringify(allEmployees) === JSON.stringify(localEmployees)
            }
            onClick={onSaveChanges}
          >
            {isLoading ? <PulseLoader size={5} color={secondary} /> : 'Save'}
          </SaveBtnWithIcon>
        </FlexEndActions>

        <Filters>
          <Checkbox.Group
            value={selectedEstablishments}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '10px',
              color: 'rgba(0, 0, 0, 0.65)',
            }}
          >
            <Label>
              <CityIcon /> Establishments:
            </Label>

            <Row>
              {establishmentNames.map(city => (
                <Col key={city} span={12}>
                  <Checkbox
                    value={city}
                    onChange={e => {
                      filtersHandler(
                        'establishment_name',
                        e.target.value,
                        e.target.checked
                      );
                    }}
                    style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                  >
                    {city}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>

          <Checkbox.Group
            value={selectedContractTypes}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              color: 'rgba(0, 0, 0, 0.65)',
            }}
          >
            <Label>
              <ContractIcon /> Contract type:
            </Label>

            <Row>
              {contractTypes.map(type => (
                <Col key={type} span={12}>
                  <Checkbox
                    value={type}
                    onChange={e => {
                      filtersHandler(
                        'contract_type',
                        e.target.value,
                        e.target.checked
                      );
                    }}
                    style={{ color: 'rgba(0, 0, 0, 0.65)' }}
                  >
                    {type}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Filters>

        <Droppable droppableId="employees-ordering">
          {providedDroppable => {
            return (
              <Content
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
              >
                {localEmployees.map((employee, i) => {
                  const {
                    id,
                    visible,
                    last_name,
                    insertion,
                    first_name,
                    phone_number,
                    email_address,
                    contract_type,
                    residence_city,
                    allowed_methods,
                    establishment_name,
                  } = employee;

                  const residenceInfo = residence_city
                    ? `${establishment_name} - ${residence_city}`
                    : establishment_name;

                  return (
                    <Draggable key={id} index={i} draggableId={String(id)}>
                      {providedDraggable => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                        >
                          <Employee
                            key={id}
                            isDnd
                            index={i}
                            visible={visible}
                            empoyeeId={id}
                            methods={allowed_methods}
                            phoneNumber={phone_number}
                            emailAddress={email_address}
                            contractType={contract_type}
                            name={`${first_name} ${insertion} ${last_name}`}
                            info={residenceInfo}
                            hideEmployee={singleEmployeeSelectionHandler}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {providedDroppable.placeholder}
              </Content>
            );
          }}
        </Droppable>
      </Container>
    </DragDropContext>
  );
};

export default EmployeeDndModal;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 400px; //300px
  height: 100%;
  padding-top: 15px;
  background: #fff;
  border-radius: 0 4px 4px 0;
`;

const FlexEndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const HoverCancelPlanningBtn = styled(DeletePlanningBtn)`
  margin-right: 10px;

  &:hover {
    transition: all 0.3s ease-in-out;
    color: ${basic};
    background: red;
  }
`;

const Filters = styled.div`
  padding: 15px;
  border-top: ${border};
  border-bottom: ${border};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-bottom: 2px;
  border-bottom: 1px solid #4343;
  font-size: 16px;
  font-weight: 600;
`;

const iconStyles = css`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const CityIcon = styled(FaCity)`
  ${iconStyles};
`;

const ContractIcon = styled(FaFileContract)`
  ${iconStyles};
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;
