// DATE & TIME FORMATS
export const TIME_FORMAT = 'HH:mm';
export const ACCURATE_TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DATE_TIME_FORMAT = `${MOMENT_DATE_FORMAT} ${ACCURATE_TIME_FORMAT}`;
export const DATE_FULL_STRING_FORMAT = 'dddd D MMMM YYYY';

// TIME PICKER
export const MINS_IN_STEP = 15;
