import moment from 'moment';
import { TIME_FORMAT, DATE_FORMAT } from '../../../constants/formats';

const defaultPatternData = {
  isDailyFirstOption: true,
  dayRecurrence: 1,
  weekRecurrence: 1,
  checkedDays: [1],
  isMonthlyFirstOption: true,
  dayNumber: 1,
  monthRecurrence: 1,
  occurrenceDay: 1,
  weekDay: 1,
  isYearlyFirstOption: true,
  yearRecurrence: 1,
  monthNumber: 1,
  isActivitiesNumberSet: false,
  activitiesNumber: 2,
};

export const defaultInitPattern = {
  initPatternType: 'daily',
  initPatternData: defaultPatternData,
  initRecurrenceRange: {},
};

export const createRecurrencePayload = (
  { startTime, endTime, startLoop, endLoop },
  activityReqObj,
  patternType,
  patternData
) => {
  const reqObj = {
    type: activityReqObj.type,
    title: activityReqObj.title,
    description: activityReqObj.description,
    employee_id: activityReqObj.employee_id,
    work_order_id: activityReqObj.work_order_id,

    recurrence_period_type: patternType,
    appointment_start_time: startTime.format(TIME_FORMAT),
    appointment_end_time: endTime.format(TIME_FORMAT),
    recurrence_start: startLoop.format(DATE_FORMAT),
  };

  if (reqObj.type === 8) {
    reqObj.customer_id = activityReqObj.customer_id;
    reqObj.work_location_id = activityReqObj.work_location_id;
  }

  const {
    isDailyFirstOption,
    dayRecurrence,
    weekRecurrence,
    checkedDays,
    isMonthlyFirstOption,
    dayNumber,
    monthRecurrence,
    occurrenceDay,
    weekDay,
    isYearlyFirstOption,
    yearRecurrence,
    monthNumber,
    isActivitiesNumberSet,
    activitiesNumber,
  } = patternData;

  if (isActivitiesNumberSet) {
    reqObj.recurrence_end_after = activitiesNumber;
  } else {
    reqObj.recurrence_end = endLoop.format(DATE_FORMAT);
  }

  switch (patternType) {
    case 'daily': {
      if (isDailyFirstOption) {
        reqObj.daily_every_day_number = dayRecurrence;
      } else {
        reqObj.daily_every_weekday = true;
      }
      break;
    }
    case 'weekly': {
      reqObj.weekly_every_week_number = weekRecurrence;
      reqObj.weekly_week_days = checkedDays;
      break;
    }
    case 'monthly': {
      if (isMonthlyFirstOption) {
        reqObj.monthly_month_day_number = dayNumber;
      } else {
        reqObj.monthly_occurrence_number = occurrenceDay;
        reqObj.monthly_week_day = weekDay;
      }

      reqObj.monthly_every_month_number = monthRecurrence;
      break;
    }
    case 'yearly': {
      if (isYearlyFirstOption) {
        reqObj.yearly_month = monthNumber;
        reqObj.yearly_month_day = dayNumber;
      } else {
        reqObj.yearly_week_day_number = occurrenceDay;
        reqObj.yearly_week_day = weekDay;
        reqObj.yearly_week_day_month = monthNumber;
      }
      reqObj.yearly_every_number = yearRecurrence;
      break;
    }
    default:
      break;
  }

  return reqObj;
};

export const mapServerPattern = resData => {
  const data = { ...defaultPatternData };
  const range = {
    startLoop: moment(resData.recurrence_start),
  };

  if (resData.recurrence_end_after) {
    data.isActivitiesNumberSet = true;
    data.activitiesNumber = resData.recurrence_end_after;
  } else {
    range.endLoop = moment(resData.recurrence_end);
  }

  switch (resData.recurrence_period_type) {
    case 'daily': {
      if (resData.daily_every_weekday) {
        data.isDailyFirstOption = false;
      } else {
        data.isDailyFirstOption = true;
        data.dayRecurrence = resData.daily_every_day_number;
      }
      break;
    }
    case 'weekly': {
      data.weekRecurrence = resData.weekly_every_week_number;
      data.checkedDays = resData.weekly_week_days;
      break;
    }
    case 'monthly': {
      if (resData.monthly_month_day_number) {
        data.dayNumber = resData.monthly_month_day_number;
      } else {
        data.isMonthlyFirstOption = false;
        data.occurrenceDay = resData.monthly_occurrence_number;
        data.weekDay = +resData.monthly_week_day;
      }
      data.monthRecurrence = resData.monthly_every_month_number;
      break;
    }
    case 'yearly': {
      if (resData.yearly_month) {
        data.monthNumber = +resData.yearly_month;
        data.dayNumber = resData.yearly_month_day;
      } else {
        data.isYearlyFirstOption = false;
        data.occurrenceDay = resData.yearly_week_day_number;
        data.weekDay = +resData.yearly_week_day;
        data.monthRecurrence = +resData.yearly_week_day_month;
      }
      data.yearRecurrence = resData.yearly_every_number;
      break;
    }
    default:
      break;
  }

  return {
    initPatternData: data,
    initRecurrenceRange: range,
    initPatternType: resData.recurrence_period_type,
  };
};
