import { HOUR_WIDTH, GRID_LINE_WIDTH } from '../constants/continuum';
import {
  workingDay,
  nightAndDay,
  horizontalLines,
  secondaryBorderColor,
} from '../constants/stylesConstants';

export function renderVerticalLines(k = 1) {
  return `repeating-linear-gradient(
        90deg,
        transparent,
        transparent ${HOUR_WIDTH * k - GRID_LINE_WIDTH}px,
        ${secondaryBorderColor} ${HOUR_WIDTH * k - GRID_LINE_WIDTH * 2}px,
        ${secondaryBorderColor} ${HOUR_WIDTH * k}px
      )`;
}

export function renderWorkingDayBgGrid(k = 1) {
  return `${renderVerticalLines(k)}, ${horizontalLines}, ${workingDay}`;
}

export function renderDayOffDayBgGrid(k = 1) {
  return `${renderVerticalLines(k)}, ${horizontalLines}, ${nightAndDay}`;
}
