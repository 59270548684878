import styled, { css } from 'styled-components';
import { critical } from '../../constants/stylesConstants';
import { flexCenter, inheritSize } from './styledComponents';

export const errorStyles = css`
  color: ${critical};
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const SpecificError = styled.div`
  min-height: 5px;
  ${errorStyles}
`;

export const GeneralError = styled.div`
  ${flexCenter}
  ${errorStyles}
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

export const PageError = styled.div`
  ${flexCenter}
  ${errorStyles}
  ${inheritSize}
  font-size: 24px;
  font-weight: 500;
`;
