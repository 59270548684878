import {
  MIN_DURATION_MINUTES,
  MAX_DURATION_MINUTES,
} from '../constants/continuum';
import {
  noRights,
  weekDaysErrorMsg,
  minActivityDurationError,
  maxActivityDurationError,
} from '../constants/errorsMsgs';
import { getDiffInMinutes } from './datesUtils';
import { showErrorNotification } from './errorHandlers';

export const validateDuration = (values, setError, error) => {
  const duration = getDiffInMinutes(values);

  if (error) setError(null);

  if (duration < MIN_DURATION_MINUTES) {
    setError(minActivityDurationError);
    return false;
  }

  if (duration > MAX_DURATION_MINUTES) {
    setError(maxActivityDurationError);
    return false;
  }

  return true;
};

export const normalizeNumberValue = (value, min = 1, max = 1e3) => {
  if (Number.isNaN(+value)) return min;
  if (+value < min) return min;
  if (+value > max) return max;
  return +value;
};

export const validateWeekDays = (days, setError, error) => {
  if (error) setError(null);
  if (days.length < 1) {
    setError(weekDaysErrorMsg);
    return false;
  }
  return true;
};

export const isAllowed = (allowedEmployees, employees, employeeIndex) =>
  allowedEmployees.some(
    allowedEmployee => allowedEmployee.id === employees[employeeIndex].id
  );

export const noPermissionForAction = (
  isUpdateAll,
  allowedEmployees,
  employees,
  employeeIndex
) => {
  if (
    (!isUpdateAll || allowedEmployees.length) &&
    !isAllowed(allowedEmployees, employees, employeeIndex)
  ) {
    showErrorNotification(noRights);
    return true;
  }
  return false;
};

export const noPermissionForResize = (
  isUpdateAll,
  allowedEmployees,
  employeeId
) => {
  if (
    (!isUpdateAll || allowedEmployees.length) &&
    !allowedEmployees.some(allowedEmployee => allowedEmployee.id === employeeId)
  ) {
    showErrorNotification(noRights);
    return true;
  }
  return false;
};
