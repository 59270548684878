import { useState } from 'react';
import { Select, Spin, Button } from 'antd';
import { FlexBeetwen, TwoBtnWrapper } from '../../../common/styledComponents';
import { Container, WideSelect } from '../Customer/CustomerSelector';
import { filterProjectOptions } from '../../../../utils/search';
import API from '../../../../api';
import { handleServerError } from '../../../../utils/errorHandlers';
import { CenterAntSpin } from '../../../common/Spinners';

const { Option } = Select;
const project = 'Project';

export default ({
  prev,
  next,
  exit,
  projects,
  projectId,
  preparePropsForWorkOrder,
}) => {
  const [query, setQuery] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const handleChange = async id => {
    setIsRequesting(true);
    try {
      const data = await API.getProject(id);
      preparePropsForWorkOrder({
        id,
        email: data.contact_email,
        phone: data.contact_phone_number,
        contactId: data.contact_id,
        locationId: data.work_location_id,
        purchaseNumber: data.purchase_number,
        establishId: data.establishment_id,
        description: data.description,
      });
    } catch (e) {
      handleServerError(e);
      preparePropsForWorkOrder({ id });
    }
    setIsRequesting(false);
  };

  return (
    <Container>
      {projects ? (
        <WideSelect
          size="large"
          showSearch
          placeholder={project}
          name={project}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          disabled={isRequesting}
          onSearch={value => setQuery(value)}
          onChange={value => handleChange(value)}
          defaultValue={typeof projectId === 'number' ? projectId : undefined}
        >
          {filterProjectOptions(projects, query).map(({ name, number, id }) => (
            <Option key={id} value={id}>
              {`${name} | ${number}`}
            </Option>
          ))}
        </WideSelect>
      ) : (
        <CenterAntSpin />
      )}
      <FlexBeetwen>
        <Button type="dashed" disabled={!projects} onClick={exit}>
          Exit
        </Button>
        <TwoBtnWrapper>
          <Button onClick={prev} disabled={!projects}>
            Back
          </Button>
          {isRequesting ? (
            <Spin />
          ) : (
            <Button onClick={next} type="primary" disabled={!projectId}>
              Next
            </Button>
          )}
        </TwoBtnWrapper>
      </FlexBeetwen>
    </Container>
  );
};
