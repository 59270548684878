import React from 'react';
import styled from 'styled-components';

import H1 from '../common/MainHeader';
import { basic, danger } from '../../constants/stylesConstants';
import { CancelBtn, SaveBtnWithIcon } from '../common/Button';

const defaultText = 'Are you sure you want to delete this activity?';

export default ({ rejectCallback, resolveCallback, content }) => {
  return (
    <DeletePlanning>
      <Title>
        <H1 type="modal">DELETE</H1>
      </Title>
      <Content>
        <TextMessage>{content || defaultText}</TextMessage>
      </Content>
      <FlexActions>
        <MarginRight>
          <CancelBtn onClick={rejectCallback}>Cancel</CancelBtn>
        </MarginRight>

        <SaveBtnWithIcon onClick={resolveCallback} isDelete>
          Delete
        </SaveBtnWithIcon>
      </FlexActions>
    </DeletePlanning>
  );
};

const DeletePlanning = styled.div`
  width: 524px;
  height: fit-content;
  border-radius: 10px;
  background: ${basic};
  padding-bottom: 25px;
  overflow: hidden;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${basic};
  height: 60px;
  background: ${danger};
`;

const Content = styled.div`
  padding: 20px 30px;
`;

const TextMessage = styled.p`
  font-size: 20px;
  width: 288px;
  text-align: center;
  margin: 0 auto;
  font-weight: 700;
  line-height: 30px;
`;

const FlexActions = styled.div`
  padding: 0px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MarginRight = styled.div`
  margin-right: 10px;
`;
