/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';

import MessageSvg from '../../../public/icons/message.svg';
import TelephoneSvg from '../../../public/icons/telephone.svg';
import { text, secondaryBorderColor } from '../../../constants/stylesConstants';

export default ({
  customerData: {
    customer_name: cusName,
    client_project_leader: clLeader,
    client_project_leader_email: clLeaderEmail,
    client_project_leader_phone_number: clLeaderPhone,
  },
}) => {
  const emails = clLeaderEmail ? clLeaderEmail.split(' / ') : [];
  const telephoneNumbers = clLeaderPhone ? clLeaderPhone.split(' / ') : [];

  return (
    <CustomerData>
      <H4>Customer data</H4>

      <Container>
        <Row>
          <Col>
            <Title>Customer name:</Title>
          </Col>

          <DescriptionWrapper>
            <DescriptionUpperText>{cusName}</DescriptionUpperText>
          </DescriptionWrapper>
        </Row>

        <Row>
          <Col>
            <Title>Client Project leader:</Title>
          </Col>

          <DescriptionWrapper>
            <Description>{clLeader}</Description>

            <Flex>
              <TelephoneIcon />

              <Column>
                {telephoneNumbers.length
                  ? telephoneNumbers.map(telephone => (
                      <InfoLink
                        href={
                          telephone
                            ? `tel:${telephone.replace(/\s/g, '')}`
                            : '#'
                        }
                        key={telephone}
                      >
                        {telephone}
                      </InfoLink>
                    ))
                  : 'private'}
              </Column>
            </Flex>

            <Flex>
              <MessageIcon />

              <Column>
                {emails.length
                  ? emails.map(email => (
                      <InfoLink
                        key={email}
                        href={email ? `mailto:${email}?` : '#'}
                        target="_top"
                      >
                        {email}
                      </InfoLink>
                    ))
                  : 'private'}
              </Column>
            </Flex>
          </DescriptionWrapper>
        </Row>
      </Container>
    </CustomerData>
  );
};

const CustomerData = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${secondaryBorderColor};
`;

const Container = styled.div`
  padding-top: 8px;
`;

const H4 = styled.h4`
  font-size: 18px;
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Col = styled.div`
  flex-basis: 25%;
  max-width: 25%;
`;

const Title = styled.p`
  font-size: 16px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
`;

const Description = styled.div`
  font-size: 16px;
  vertical-align: middle;
  overflow-wrap: break-word;
`;

const DescriptionUpperText = styled(Description)`
  text-transform: uppercase;
`;

const Column = styled.p`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  color: ${text};
`;

const TelephoneIcon = styled(TelephoneSvg)`
  margin-right: 11px;
`;

const MessageIcon = styled(MessageSvg)`
  margin-right: 11px;
`;

const InfoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  color: ${text};
  line-break: loose;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
