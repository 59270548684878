export const filterOptions = (options, query, field) => {
  const searchQuery = query.trim().toLowerCase();
  if (!searchQuery) return options;
  return options.filter(option => {
    return option[field]
      .split(' ')
      .some(str => str.toLowerCase().startsWith(searchQuery));
  });
};

export const filterProjectOptions = (options, query) => {
  const searchQuery = query.trim().toLowerCase();
  if (!query) return options;
  return options.filter(option => {
    const isMatchByQuery =
      option.name
        .split(' ')
        .some(str => str.toLowerCase().startsWith(searchQuery)) ||
      option.number.toLowerCase().includes(searchQuery);

    return isMatchByQuery;
  });
};

export const restructArray = (array, id, fieldName) => {
  if (!id || !array || !array.length) return array;
  let i = 0;
  array.some((object, index) => {
    if (id === object[fieldName]) {
      i = index;
      return true;
    }
    return false;
  });
  if (!i) return array;
  const arrayCopy = [...array];
  // eslint-disable-next-line
  arrayCopy[i] = array[0];
  arrayCopy[0] = array[i];
  return arrayCopy;
};

export const simplifyLocations = locations =>
  locations.map(({ id, street, house_number, city }) => ({
    id,
    address: `${street} ${house_number}, ${city}`,
  }));
