import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useClickAway } from 'react-use';
import { FiTrash2 } from 'react-icons/fi';
import {
  basic,
  zDrag,
  text,
  contextMenuShadow,
} from '../../constants/stylesConstants';
import CopySvg from '../../public/icons/copy.svg';
import { useFetchData } from '../../utils/hooks';
import API from '../../api';

import { showErrorNotification } from '../../utils/errorHandlers';
import { noRights } from '../../constants/errorsMsgs';

const CustomContextMenu = ({
  id,
  closeSelf,
  coordinates,
  openCopyModal,
  openDeletingModal,
}) => {
  const selfRef = useRef();
  const [isLoading, _isError, isEditable] = useFetchData(
    API.checkPermission,
    id
  );

  useClickAway(selfRef, closeSelf);

  useEffect(() => {
    if (!isEditable && !isLoading) {
      showErrorNotification(noRights);
    }
  }, [isEditable, isLoading]);

  if (isEditable)
    return (
      <Container ref={selfRef} coords={coordinates}>
        <Menu>
          <Option
            onMouseDown={e => e.stopPropagation()}
            onClick={() => {
              openDeletingModal();
              closeSelf();
            }}
          >
            <TrashIcon /> delete activity
          </Option>

          <Option
            onMouseDown={e => e.stopPropagation()}
            onClick={() => {
              openCopyModal();
              closeSelf();
            }}
          >
            <CopyIcon /> duplicate activity
          </Option>
        </Menu>
      </Container>
    );
  return null;
};

const Container = styled.div`
  color: ${text};
  position: fixed;
  width: fit-content;
  top: ${p => `${p.coords.clientY - 70}px`};
  left: ${p => `${p.coords.clientX}px`};
  box-shadow: ${contextMenuShadow};
  transition: 0.2s display ease-in;
  background: ${basic};
  text-align: center;
  z-index: ${zDrag};
  border-radius: 9px;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 10px 0;
  z-index: 1;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  font-weight: 500;
  z-index: 1;
  font-size: 14px;
  padding: 10px 40px 10px 20px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const iconStyles = css`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

const TrashIcon = styled(FiTrash2)`
  ${iconStyles}
  margin-top: -2px;
`;

const CopyIcon = styled(CopySvg)`
  ${iconStyles}
`;

export default CustomContextMenu;
