import React from 'react';
import styled from 'styled-components';
import { border } from '../../../constants/stylesConstants';
import Row from './Row';

const EmployeeTable = ({ data, setEmployeesState, employees, isEditable }) => {
  return (
    <div>
      <div>
        <HeadRow>
          <HeadColumn>Start date</HeadColumn>
          <HeadColumn>End date</HeadColumn>
          <HeadColumn>Start time</HeadColumn>
          <HeadColumn>End time</HeadColumn>
          {isEditable && (
            <GroupColumn>
              <HeadColumn>Edit</HeadColumn>
              <HeadColumn>Delete</HeadColumn>
            </GroupColumn>
          )}
        </HeadRow>
      </div>

      <div>
        {data &&
          data.map(rowData => (
            <Row
              isEditable={isEditable}
              setEmployeesState={setEmployeesState}
              key={rowData.id}
              rowData={rowData}
              employees={employees}
            />
          ))}
      </div>
    </div>
  );
};

const HeadRow = styled.div`
  display: flex;
  border: ${border};
`;

const HeadColumn = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  flex-grow: 1;
  border-right: ${border};
  font-weight: 700;
  &:last-child {
    border-right: 0px;
  }
`;

const GroupColumn = styled.div`
  text-align: center;
  width: 20%;
  display: flex;
  div {
    width: 50%;
  }
`;

export default EmployeeTable;
