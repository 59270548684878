import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';
import MainHeader from '../../../common/MainHeader';
import { basic, text, primary } from '../../../../constants/stylesConstants';
import { PrimaryBtn } from '../../../common/Button';

const ConfirmMessage = ({ hideConfirmMessage }) => {
  return (
    <Container>
      <Title>
        <MainHeader type="modal">Message</MainHeader>
      </Title>
      <Content>
        <MarginRight>
          <TriangleIcon />
        </MarginRight>

        <Text>
          Some months have fewer than 30 or 31 days. For these months, the
          occurrence will fall on the last day of the month.
        </Text>
      </Content>
      <JustifyCenter>
        <MarginRight>
          <SaveBtn onClick={hideConfirmMessage} type="button">
            OK
          </SaveBtn>
        </MarginRight>
      </JustifyCenter>
    </Container>
  );
};

export default ConfirmMessage;

const Container = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 600px;
  height: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
  border-radius: 10px 10px;
  background: ${basic};
  color: ${text};
  overflow: hidden;
  padding-bottom: 25px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${basic};
  height: 60px;
  background: ${primary};
`;

const JustifyCenter = styled.div`
  display: flex;
  padding: 0px 30px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
`;

const MarginRight = styled.div`
  margin-right: 15px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 17px;
`;

const Text = styled.p`
  text-align: center;
`;

const SaveBtn = styled(PrimaryBtn)`
  padding: 10px 20px;
  border-radius: 5px;
`;

const TriangleIcon = styled(FaExclamationTriangle)`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  path {
    fill: orange;
  }
`;
