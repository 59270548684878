import React, { memo, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { HOUR_WIDTH, BLOCK_HEIGHT } from '../../../constants/continuum';
import { titleTaskColor } from '../../../constants/stylesConstants';
import Modal from '../Modal';
import WorkOrderModal from '../WorkOrder';
import CommonActivityModal from '../Common';
import { updateRowConflicts } from '../../../coreFuncs/overlaping';

const padding = 7;

export default memo(
  ({
    id,
    type,
    city,
    title,
    color,
    customer,
    endPoint,
    employeeId,
    customerId,
    startPoint,
    description,
    workOrderId,
    recurrenceId,
    workLocationId,
    hideConflictModal,
  }) => {
    const [isEditModalShown, setIsEditModalShown] = useState(false);
    const [isWorkOrderModalShown, setIsWorkOrderModalShown] = useState(false);

    const taskTitle = useMemo(() => {
      const fleshes = title.match(/\//g) || [];

      if (workOrderId && fleshes.length === 1) {
        return `${customer} / ${title} / ${city}`;
      }

      return title;
    }, [title, workOrderId]);

    const closeEditModal = useCallback(() => {
      setIsEditModalShown(false);
    }, []);
    const closeWorkOrderModal = useCallback(() => {
      setIsWorkOrderModalShown(false);
    }, []);

    const onClickCallback = useCallback(() => {
      if (type === 2) setIsWorkOrderModalShown(true);
      else setIsEditModalShown(true);
    }, [type]);

    return (
      <>
        <Block
          color={color}
          onDragStart={() => false}
          cursor="grab"
          onMouseDown={onClickCallback}
        >
          {taskTitle}
        </Block>

        {isEditModalShown && (
          <Modal onHideModal={closeEditModal}>
            <CommonActivityModal
              activityId={id}
              recurrenceId={recurrenceId}
              type={type}
              title={taskTitle}
              modalType="edit"
              customerId={customerId}
              employeeId={employeeId}
              description={description}
              workOrderId={workOrderId}
              onHideModal={closeEditModal}
              workLocationId={workLocationId}
              hideConflictModal={hideConflictModal}
              updateRowConflicts={updateRowConflicts}
              dates={{ start: startPoint, end: endPoint }}
            />
          </Modal>
        )}
        {isWorkOrderModalShown && (
          <Modal onHideModal={closeWorkOrderModal}>
            <WorkOrderModal
              title={taskTitle}
              activityId={id}
              selectedEmployee={employeeId}
              onHideModal={closeWorkOrderModal}
            />
          </Modal>
        )}
      </>
    );
  }
);

const Block = styled.div`
  min-width: ${HOUR_WIDTH}px;
  height: ${BLOCK_HEIGHT}px;
  line-height: ${BLOCK_HEIGHT}px;
  padding: 0 ${padding}px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  background: ${p => p.color || 'olive'};
  color: ${titleTaskColor};
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: grab;
`;
