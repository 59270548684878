import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Steps } from 'antd';
import { basic, primary } from '../../../constants/stylesConstants';
import Customer from './Customer';
import Project from './Project';
import H1 from '../../common/MainHeader';
import CloseSvg from '../../../public/icons/close.svg';
import NewWorkOrder from './NewWorkOrder';
import API from '../../../api';
import { handleServerError } from '../../../utils/errorHandlers';
import { useRootContext } from '../../../context';
import NewWorkOrder2 from './NewWorkOrder2';
import { isQERP } from '../../../utils/hooks';

const makeLocationOptions = locations => {
  return locations.map(({ id, city, street, house_number: houseN }) => {
    return { id, address: `${street} ${houseN}, ${city}` };
  });
};

const { Step } = Steps;

const getSteps = isQerpUrl => {
  return isQerpUrl
    ? ['Customer', 'Project', 'Work Order [Step 1]', 'Work Order [Step 2]']
    : ['Customer', 'Project', 'Work Order'];
};

const Wizard = ({ employeeId, exit, finish, activityData, finish2 }) => {
  const {
    state: { positionsMap, employees },
  } = useRootContext();
  const { last_name: lastName, first_name: firstName, insertion } = employees[
    positionsMap[employeeId]
  ];

  const isQerpUrl = isQERP(window.location.host);
  const steps = getSteps(isQerpUrl);

  const fullName = `${firstName} ${insertion} ${lastName}`;
  const [count, setCount] = useState(0);
  const prev = () => setCount(i => (i - 1 < 0 ? 0 : i - 1));
  const next = () =>
    setCount(i => (i + 1 > steps.length ? steps.length : i + 1));

  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customers, setCustomers] = useState(null);
  const pushNewCustomer = customer => setCustomers([customer, ...customers]);

  const fetchCustomers = async () => {
    try {
      const data = await API.getCustomers();
      setCustomers(data);
    } catch (e) {
      handleServerError(e);
    }
  };

  const [establishmentId, setEstablishmentId] = useState(null);
  const [establishments, setEstablishments] = useState([]);
  const [step1Data, setStep1Data] = useState(null);

  const fetchEstablishments = async () => {
    try {
      const data = await API.getProjectEstablishments();
      setEstablishments(data.establishments);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchEstablishments();
  }, []);

  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState(null);
  const pushNewProject = project => setProjects([project, ...projects]);

  const fetchProjects = async id => {
    try {
      const data = await API.getProjects(id);
      setProjects(data.projects);
    } catch (e) {
      handleServerError(e);
    }
  };

  const [customerContacts, setCustomerContacts] = useState([]);

  const fetchCustomerContacts = async id => {
    try {
      const data = await API.getProjectCustomerContacts(id);
      setCustomerContacts(data.contacts);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (customerId) {
      setProjectId(null);
      fetchProjects(customerId);
      fetchCustomerContacts(customerId);
    }
  }, [customerId]);

  const [projectOptions, setProjectOptions] = useState({
    leaders: [],
    coordinators: [],
    locations: [],
  });

  const fetchProjectOptions = async id => {
    try {
      const data = await API.getProjectOptions(id);

      setProjectOptions({
        leaders: data.project_leaders,
        coordinators: data.project_coordinators,
        locations: makeLocationOptions(data.work_locations),
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (establishmentId) {
      setProjectOptions({});
      fetchProjectOptions(establishmentId);
    }
  }, [establishmentId]);

  const [projectData, setProjectData] = useState({});

  const preparePropsForWorkOrder = ({
    id,
    name,
    email,
    phone,
    contactId,
    locationId,
    description,
    establishId,
    purchaseNumber,
  }) => {
    if (name) pushNewProject({ id, name });
    else if (establishId) setEstablishmentId(establishId);
    setProjectData({
      email,
      phone,
      contactId,
      locationId,
      description,
      purchaseNumber,
    });
    setProjectId(id);
  };

  const renderCurrent = () => {
    switch (count) {
      case 0:
        return (
          <Customer
            exit={exit}
            next={next}
            customers={customers}
            pushNewCustomer={pushNewCustomer}
            customerId={customerId}
            setCustomerId={setCustomerId}
            isNewCustomer={isNewCustomer}
            setIsNewCustomer={setIsNewCustomer}
          />
        );
      case 1:
        return (
          <Project
            exit={exit}
            next={next}
            prev={prev}
            customerId={customerId}
            isNewCustomer={isNewCustomer}
            projectId={projectId}
            projects={projects}
            establishments={establishments}
            setEstablishmentId={setEstablishmentId}
            customerContacts={customerContacts}
            projectOptions={projectOptions}
            preparePropsForWorkOrder={preparePropsForWorkOrder}
          />
        );
      case 2:
        return (
          <NewWorkOrder
            exit={exit}
            prev={prev}
            next={next}
            finish={finish}
            setStep1Data={setStep1Data}
            projectId={projectId}
            customerContacts={customerContacts}
            preparePropsForWorkOrder={preparePropsForWorkOrder}
            locations={projectOptions.locations}
            projectData={projectData}
          />
        );

      case 3:
        return (
          <NewWorkOrder2
            exit={exit}
            prev={prev}
            finish2={finish2}
            step1Data={step1Data}
            projectId={projectId}
            activityData={activityData}
          />
        );

      default:
        return (
          <Customer
            exit={exit}
            next={next}
            customers={customers}
            pushNewCustomer={pushNewCustomer}
            customerId={customerId}
            setCustomerId={setCustomerId}
          />
        );
    }
  };

  return (
    <div>
      <Title>
        <H1 type="modal">{`New work order for ${fullName}`}</H1>
        <CloseIcon onClick={exit} />
      </Title>
      <Container>
        <Steps current={count}>
          {steps.map(item => (
            <Step key={item} title={item} />
          ))}
        </Steps>
        <StepsContent>{renderCurrent()}</StepsContent>
      </Container>
    </div>
  );
};

export default Wizard;

const Container = styled.div`
  min-width: 800px;
  background: ${basic};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  width: fit-content;
`;

const StepsContent = styled.div`
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
`;

const Title = styled.div`
  position: relative;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: ${primary};
`;

const CloseIcon = styled(CloseSvg)`
  position: absolute;
  cursor: pointer;
  right: 30px;
`;
