export const WEEK_DAYS = Object.freeze([
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
]);

export const MONTH_DAYS = Object.freeze([
  { label: 'first', value: 1 },
  { label: 'second', value: 2 },
  { label: 'third', value: 3 },
  { label: 'fourth', value: 4 },
  { label: 'last', value: 5 },
]);

export const MONTHS = Object.freeze([
  { label: 'January ', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August ', value: 8 },
  { label: 'September ', value: 9 },
  { label: 'October ', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]);
