import { Tabs } from 'antd';
import NewProject from './NewProject';
import ProjectSelector from './ProjectSelector';

const { TabPane } = Tabs;

export default ({
  exit,
  prev,
  next,
  customerId,
  projects,
  projectId,
  establishments,
  setEstablishmentId,
  customerContacts,
  projectOptions,
  preparePropsForWorkOrder,
  isNewCustomer,
}) => {
  const isSelectorShown = !isNewCustomer && projects && !!projects.length;

  return (
    <div>
      <Tabs defaultActiveKey="1">
        {isSelectorShown && (
          <TabPane tab="Select existing project" key="1">
            <ProjectSelector
              exit={exit}
              prev={prev}
              next={next}
              projects={projects}
              projectId={projectId}
              preparePropsForWorkOrder={preparePropsForWorkOrder}
            />
          </TabPane>
        )}
        <TabPane tab="Create new project" key={isSelectorShown ? 2 : 1}>
          <NewProject
            exit={exit}
            prev={prev}
            next={next}
            customerId={customerId}
            establishments={establishments}
            setEstablishmentId={setEstablishmentId}
            customerContacts={customerContacts}
            projectOptions={projectOptions}
            preparePropsForWorkOrder={preparePropsForWorkOrder}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
