import moment from 'moment';
import { DAY_TITLE_HEIGHT } from '../constants/continuum';

export const transformYtoIndex = (y, rowH) =>
  Math.round((y - DAY_TITLE_HEIGHT) / rowH);

export const transformIndexToY = (i, rowH, topPadding) =>
  i * rowH + topPadding + DAY_TITLE_HEIGHT;

export const getDiscreteY = (y, rowH, topPadding) => {
  const rem = (y - DAY_TITLE_HEIGHT) % rowH;
  return y - rem + topPadding;
};

export const getMiddleY = (y, blockH, rowH, topPadding) => {
  const rem = (y - DAY_TITLE_HEIGHT) % rowH;
  const diff = rowH - rem;

  if (diff < blockH / 2) return y + diff + topPadding;
  return y - rem + topPadding;
};

export const transformDatesToX = (zero, start, end, minuteW) => {
  return {
    timeX: moment(start).diff(zero, 'minutes') * minuteW,
    timeW: moment(end).diff(start, 'minutes') * minuteW,
  };
};

export const transformXtoMoment = (zeroDate, x, hourW) =>
  moment(zeroDate).add(Math.round((x / hourW) * 4) / 4, 'hours');

export const transformXWtoMoments = (zeroDate, x, width, hourW) => {
  const start = transformXtoMoment(zeroDate, x, hourW);
  const end = transformXtoMoment(start, width, hourW);
  return { start, end };
};

const geBordersW = (x, colW, borderW) =>
  Math.floor(x / (colW + borderW)) * borderW;

const getStartBorderW = (x, colW, borderW, stepW) => {
  const offsetW = colW + borderW;
  const rem = x % offsetW;

  if (rem > colW - stepW / 2) return offsetW - rem;
  return 0;
};

const getEndBorderW = (right, stepW, colW, borderW) => {
  const rem = right % (colW + borderW);

  if (rem > colW) return rem - colW;
  if (rem < stepW / 2) return rem + borderW;
  return 0;
};

export const getTimeX = (viewX, colW, borderW) =>
  viewX - geBordersW(viewX, colW, borderW);

export const getViewX = (timeX, colW, borderW) =>
  timeX + Math.floor(timeX / colW) * borderW;

export const getTimeW = (viewX, viewW, colW, borderW) =>
  getTimeX(viewX + viewW, colW, borderW) - getTimeX(viewX, colW, borderW);

export const getViewXW = (timeX, timeW, stepW, colW, borderW) => {
  const viewStartX = getViewX(timeX, colW, borderW);
  const viewEndX = getViewX(timeX + timeW, colW, borderW);
  const endBorderW = getEndBorderW(viewEndX, stepW, colW, borderW);

  return {
    viewX: viewStartX,
    viewW: viewEndX - viewStartX - endBorderW,
  };
};

export const getDiscreteTimeX = (x, stepW, colW, borderW) => {
  const bordersW = geBordersW(x, colW, borderW);
  const startBorderW = getStartBorderW(x, colW, borderW, stepW);
  const rem = (x - bordersW) % stepW;

  if (startBorderW) return x + startBorderW - bordersW - borderW;
  if (rem > stepW / 2) return x + stepW - rem - bordersW;
  return x - rem - bordersW;
};

export const getDiscreteRightResizedXW = (x, width, stepW, colW, borderW) => {
  const endBorderW = getEndBorderW(x + width, stepW, colW, borderW);
  const bordersWAfter = geBordersW(x + width, colW, borderW);
  const bordersWBefore = geBordersW(x, colW, borderW);
  const innerBordersW = bordersWAfter - bordersWBefore;
  const timeX = x - bordersWBefore;

  if (endBorderW) {
    const viewW = width - endBorderW;
    const timeW = viewW - innerBordersW;

    return { timeX, timeW, viewW };
  }

  const rem = (width - innerBordersW) % stepW;

  if (rem > stepW / 2) {
    const viewW = width + stepW - rem;
    const timeW = viewW - innerBordersW;

    return { timeX, timeW, viewW };
  }

  const viewW = width - rem;
  const timeW = viewW - innerBordersW;

  return { timeX, timeW, viewW };
};

export const getViewMaxW = (edge, x, timeMaxW, colW, borderW) => {
  let timeX;
  let timeRightX;

  if (edge === 'right') {
    timeX = getTimeX(x, colW, borderW);
    timeRightX = timeX + timeMaxW;
  } else if (edge === 'left') {
    timeRightX = getTimeX(x, colW, borderW);
    timeX = timeRightX - timeMaxW;
  } else {
    timeX = x;
    timeRightX = timeX + timeMaxW;
  }

  const timeXCol = Math.floor(timeX / colW);
  const rightTimeXCol = Math.floor(timeRightX / colW);

  if (timeXCol === rightTimeXCol) return timeMaxW;
  return timeMaxW + (rightTimeXCol - timeXCol) * borderW;
};
