import styled from 'styled-components';
import Order from './Order';
import { basic } from '../../../constants/stylesConstants';
import { useRootContext } from '../../../context';

export default ({ board, grid }) => {
  const {
    state: {
      unplannedWorkOrders,
      locationFilters,
      customerFilters,
      methodFilters,
      establishmentFilters,
      unplannedOrdersSearchQuery,
    },
  } = useRootContext();

  return (
    <ListOrders>
      <Container>
        {unplannedWorkOrders.map(unplWorkOrder => {
          const isMatchedByQuery =
            !unplannedOrdersSearchQuery ||
            unplWorkOrder.work_order_number
              .toLowerCase()
              .includes(unplannedOrdersSearchQuery) ||
            unplWorkOrder.customer_name
              .toLowerCase()
              .includes(unplannedOrdersSearchQuery) ||
            unplWorkOrder.project
              .toLowerCase()
              .includes(unplannedOrdersSearchQuery);
          const isMatchedByLocation =
            !locationFilters.length ||
            locationFilters.includes(unplWorkOrder.addressLine);
          const isMatchedByCustomer =
            !customerFilters.length ||
            customerFilters.includes(unplWorkOrder.customer_name);
          const isMatchedByMethod =
            !methodFilters.length ||
            unplWorkOrder.methods.some(method =>
              methodFilters.includes(method)
            );
          const isMatchedByEstablishment =
            !establishmentFilters.length ||
            establishmentFilters.includes(unplWorkOrder.establishment_name);

          if (
            isMatchedByQuery &&
            isMatchedByLocation &&
            isMatchedByCustomer &&
            isMatchedByMethod &&
            isMatchedByEstablishment
          ) {
            return (
              <Order
                key={unplWorkOrder.id}
                board={board}
                grid={grid}
                {...unplWorkOrder}
              />
            );
          }
          return null;
        })}
      </Container>
    </ListOrders>
  );
};

const ListOrders = styled.div`
  background: ${basic};
  max-height: 750px;
  overflow-y: auto;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 17px;
  padding: 14px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  background: white;
`;
