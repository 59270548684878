import 'react-day-picker/lib/style.css';

import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import NProgress from 'nprogress';
import DayPicker from 'react-day-picker';
import { useClickAway } from 'react-use';

import { MdToday } from 'react-icons/md';
import { ArrowLeftOutlined } from '@ant-design/icons';

import {
  text,
  basic,
  primary,
  disabled,
  tabBorder,
} from '../constants/stylesConstants';
import { DATE_FORMAT } from '../constants/formats';
import { NAVIGATION_HEIGHT } from '../constants/continuum';

import Search from './ResourcePlanning/Search';
import { isQERP } from '../utils/hooks';
import { getServerUrl } from '../utils/apiUrls';
import { useRootContext } from '../context';
import { fetchOutsideDate } from '../context/actions';
import { getRequestDateFormat } from '../utils/datesUtils';

const today = moment().toLocaleString();
const daysOffset = -7;
const startProgress = 0.7;
const daysFetchAmount = 21;

const jumpTo = (selectedDate, boardRef) => {
  if (boardRef.current) {
    const selectedDateTarget = document.getElementsByClassName(
      `${moment(selectedDate).format(DATE_FORMAT)}`
    )[0];
    if (selectedDateTarget)
      boardRef.current.scrollLeft = selectedDateTarget.offsetLeft;
  }
};

const isBetweenLoadedDays = (dates, selectedDate) =>
  moment(dates[0].date)
    .twix(dates[dates.length - 1].date)
    .contains(moment(selectedDate));

export default () => {
  const {
    state: { boardRef, todayRef, dates },
    dispatch,
  } = useRootContext();

  const calendarRef = React.useRef(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isFetchToday, setIsFetchToday] = React.useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(false);

  useClickAway(calendarRef, () => setIsCalendarVisible(false));

  const handleDayClick = day => {
    setSelectedDate(day);
    setIsCalendarVisible(false);
  };

  const fetchOutsideDays = (dateFrom, offset, daysAmount, isToday) => {
    if (isToday) setIsFetchToday(true);
    else setIsLoading(true);

    NProgress.start();
    NProgress.set(startProgress);
    if (dates.length) {
      fetchOutsideDate(dispatch, {
        date: getRequestDateFormat(dateFrom),
        offset,
        days: daysAmount,
      });
    }
  };

  const scrollToToday = () => {
    if (boardRef.current && todayRef && todayRef.current) {
      boardRef.current.scrollLeft = todayRef.current.offsetLeft;
    } else {
      fetchOutsideDays(today, daysOffset, daysFetchAmount, true);
    }
    setSelectedDate(null);
  };

  const fetchToSelectDate = () => {
    if (isBetweenLoadedDays(dates, selectedDate)) {
      jumpTo(selectedDate, boardRef);
    } else {
      fetchOutsideDays(selectedDate, daysOffset, daysFetchAmount);
    }
  };

  const serverUrl = getServerUrl();

  React.useEffect(() => {
    if (dates.length && selectedDate) {
      fetchToSelectDate();
    }
  }, [boardRef, selectedDate]);

  React.useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      NProgress.done();
      jumpTo(selectedDate, boardRef);
    } else if (isFetchToday) {
      setIsFetchToday(false);
      setSelectedDate(today);
      NProgress.done();
      jumpTo(today, boardRef);
    }
  }, [dates]);

  return (
    <Container>
      <CenterPosition>
        <div>
          <Actions>
            <Link href={serverUrl} target="_blank" rel="noopener noreferrer">
              <ArrowLeftOutlined
                style={{ marginRight: '3px', fontSize: '20px' }}
              />
              {isQERP(window.location.host) ? 'QERP' : 'TERP'}
            </Link>

            <IconWrapper onClick={scrollToToday}>
              <MdToday style={{ marginRight: '3px', fontSize: '20px' }} />
              Today
            </IconWrapper>
          </Actions>

          <ChooseDate>
            <BlueText>Jump to: </BlueText>

            <ChooseDateBtn
              onMouseDown={() => {
                setTimeout(() => setIsCalendarVisible(!isCalendarVisible), 0);
              }}
            >
              {selectedDate
                ? moment(selectedDate).format(DATE_FORMAT)
                : moment().format(DATE_FORMAT)}
            </ChooseDateBtn>

            {isCalendarVisible && (
              <DayPickerWrapper ref={calendarRef}>
                <DayPicker
                  selectedDays={selectedDate}
                  onDayClick={handleDayClick}
                  showWeekNumbers
                  firstDayOfWeek={1}
                  showOutsideDays
                  month={new Date(selectedDate || today)}
                />
              </DayPickerWrapper>
            )}
          </ChooseDate>
        </div>
      </CenterPosition>

      <Flex>
        <Links>
          <TabLink>Resource planning</TabLink>
        </Links>

        <Search />
      </Flex>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  padding: 0 20px;
  color: ${text};
  align-items: flex-end;
  width: 100%;
  height: ${NAVIGATION_HEIGHT}px;
`;

const Link = styled.a`
  display: flex;
  color: ${primary};
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: color 0.2s;
  cursor: pointer;

  &:focus,
  &:active {
    border: none;
    outline: none;
  }

  &:hover svg,
  &:hover a {
    path {
      fill: #40a9ff;
    }
  }
`;

const Actions = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${primary};
  display: flex;
  align-items: center;
  width: 166px;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:hover svg {
    path {
      fill: #40a9ff;
    }
    color: #40a9ff;
  }
`;

const TabLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-width: 170px;
  border-radius: 3px 3px 0 0;
  border: ${tabBorder};
  color: ${disabled};
  font-size: 18px;
  font-weight: 700;
  padding: 0 18px;
  margin-right: 7px;
  user-select: none;

  &:first-child {
    cursor: pointer;
    background: #f1f1f1;
    color: ${primary};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const Links = styled.div`
  display: flex;
  font-size: 18px;
`;

const ChooseDate = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BlueText = styled.div`
  font-size: 14px;
  color: ${primary};
`;

const CenterPosition = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 39px;
`;

const DayPickerWrapper = styled.div`
  position: absolute;
  right: -132px;
  top: 33px;
  background: white;
  border-radius: 25px;
  z-index: 1000;
`;

const ChooseDateBtn = styled.button`
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  color: ${basic};
  background: ${primary};
  outline: none;
  border: none;
  text-align: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
