import styled from 'styled-components';
import { basic, primary } from '../../../constants/stylesConstants';
import { CancelBtn } from '../../common/Button';
import MainHeader from '../../common/MainHeader';
import ConflictTaskBlock from './ConflictTaskBlock';

const ConflictModal = ({ hideConflictModal, conflicts }) => {
  return (
    <NewEventModal>
      <Title>
        <MainHeader type="modal">Conflicts</MainHeader>
      </Title>
      <Content>
        {conflicts.map(
          ({
            id,
            type,
            city,
            color,
            title,
            description,
            end_point: endPoint,
            start_point: startPoint,
            customer_id: customerId,
            employee_id: employeeId,
            customer_name: customer,
            work_order_id: workOrderId,
            recurring_activity_id: recurrenceId,
            work_location_id: workLocationId,
          }) => (
            <ConflictTaskBlock
              key={id}
              id={id}
              type={type}
              city={city}
              title={title}
              color={color}
              customer={customer}
              endPoint={endPoint}
              employeeId={employeeId}
              customerId={customerId}
              startPoint={startPoint}
              description={description}
              workOrderId={workOrderId}
              recurrenceId={recurrenceId}
              workLocationId={workLocationId}
              hideConflictModal={hideConflictModal}
            />
          )
        )}
        <FlexEndActions>
          <CancelBtn
            type="button"
            hideConflictModal
            onClick={hideConflictModal}
          >
            Cancel
          </CancelBtn>
        </FlexEndActions>
      </Content>
    </NewEventModal>
  );
};

export default ConflictModal;

const NewEventModal = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 490px;
  font-display: flex;
  flex-direction: column;
  height: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
  border-radius: 10px 10px;
  background: ${basic};
  overflow: hidden;
  padding-bottom: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${basic};
  height: 60px;
  background: ${primary};
`;

const Content = styled.div`
  padding: 10px 30px 0px 29px;
  flex-grow: 1;
  max-height: 300px;
  overflow-y: auto;
`;

const FlexEndActions = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  align-items: center;
`;
