import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Popconfirm, Radio } from 'antd';
import EmployeeTable from './EmployeeTable';

import { removeEmployee, setActiveEmployee } from '../../../context/actions';
import { useRootContext } from '../../../context';
import TrashSvg from '../../../public/icons/trash.svg';
import SmileIcon from '../../common/Icon';
import { showErrorNotification } from '../../../utils/errorHandlers';

const warnMsg =
  'Are you sure you want to delete employee from this work order?';

export default ({ employees, setEmployeesState, isEditable, workOrderId }) => {
  const {
    dispatch,
    state: { activeEmployee },
  } = useRootContext();
  const [data, setData] = useState(null);
  const [employeesKey, setEmployeesKey] = useState(null);

  const onEmployeeChange = e => {
    setActiveEmployee(dispatch, String(e.target.value));
  };

  useEffect(() => {
    if (activeEmployee && employees[activeEmployee]) {
      const tableData = employees[activeEmployee].activities.map(
        ({
          id,
          type,
          title,
          description,
          end_point: end,
          start_point: start,
          work_order_id: orderId,
          employee_id: employeeId,
        }) => {
          const [endDate, endTime] = end.split(' ');
          const [startDate, startTime] = start.split(' ');

          return {
            id,
            type,
            title,
            orderId,
            endDate,
            endTime,
            startDate,
            startTime,
            employeeId,
            description,
          };
        }
      );
      setData(tableData);
    }
  }, [activeEmployee, employees]);

  useEffect(() => {
    if (!employeesKey) {
      const keys = Object.keys(employees);
      const index = keys.findIndex(key => key === activeEmployee);
      keys.splice(index, 1);
      keys.unshift(activeEmployee);
      setEmployeesKey(keys);
    }
  }, [employeesKey]);

  const deleteEmployee = async employeeId => {
    const reqObj = {
      work_order_id: workOrderId,
      employee_id: employeeId,
    };

    const success = await removeEmployee(
      dispatch,
      reqObj,
      showErrorNotification
    );

    if (success) {
      const employeesCopy = { ...employees };
      delete employeesCopy[employeeId];
      setEmployeesKey(Object.keys(employeesCopy));
      setEmployeesState(employeesCopy);
      setActiveEmployee(dispatch, Object.keys(employeesCopy)[0]);
    }
  };

  return (
    <OtherEmloyees>
      <H4>Employees</H4>
      <FlexContainer>
        <EmployeeWrapper>
          <Radio.Group value={activeEmployee} onChange={onEmployeeChange}>
            {employeesKey &&
              employeesKey.map(key => (
                <ChoiceWrapper key={key}>
                  <StyledRadioButton value={key}>
                    {employees[key].employee_name}
                  </StyledRadioButton>
                  {isEditable && (
                    <Popconfirm
                      title={warnMsg}
                      onConfirm={() => deleteEmployee(Number(key))}
                      icon={SmileIcon}
                      okText="Yes"
                      cancelText="No"
                    >
                      <TrashIcon />
                    </Popconfirm>
                  )}
                </ChoiceWrapper>
              ))}
          </Radio.Group>
        </EmployeeWrapper>

        {activeEmployee && (
          <TableWrapper>
            <EmployeeTable
              employees={employees}
              setEmployeesState={setEmployeesState}
              data={data}
              isEditable={isEditable}
            />
          </TableWrapper>
        )}
      </FlexContainer>
    </OtherEmloyees>
  );
};

const OtherEmloyees = styled.div`
  padding-top: 10px;
`;

const H4 = styled.h4`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
`;

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 200px;
`;

const EmployeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ChoiceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
  width: 200px;
`;

const StyledRadioButton = styled(Radio.Button)`
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  width: calc(95% - 200px);
`;

const TrashIcon = styled(TrashSvg)`
  margin-left: 10px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
