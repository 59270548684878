import styled from 'styled-components';
import { BigBtn, BigWarnBtn } from '../common/Button';
import { basic } from '../../constants/stylesConstants';

export default ({ close, openWorkorderWizard, openActivityModal }) => {
  return (
    <Container>
      <BigBtn
        onClick={() => {
          openActivityModal();
        }}
      >
        Create new activity
      </BigBtn>
      <BigBtn
        onClick={() => {
          openWorkorderWizard();
        }}
      >
        Create new work order
      </BigBtn>
      <BigWarnBtn
        onClick={() => {
          close();
        }}
      >
        Cancel
      </BigWarnBtn>
    </Container>
  );
};

const Container = styled.div`
  padding: 15px;
  border-radius: 10px;
  background-color: ${basic};
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  & > button:last-of-type {
    width: calc(100% - 4px);
    height: 44px;
    margin: 0 auto;
  }
`;
