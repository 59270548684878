import styled, { css } from 'styled-components';
import { basic } from '../../constants/stylesConstants';

const defaultStyle = css`
  font-size: 18px;
`;

const modalTitle = css`
  font-size: 20px;
  color: ${basic};
  text-transform: uppercase;
`;

export default styled.h1`
  font-weight: 700;
  ${({ type }) => (type === 'modal' ? modalTitle : defaultStyle)}
`;
