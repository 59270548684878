import styled from 'styled-components';

import HoursList from './HoursList';
import {
  secondary,
  secondaryBorderColor,
} from '../../../constants/stylesConstants';
import {
  DAY_BORDER_WIDTH,
  DAY_TITLE_HEIGHT,
} from '../../../constants/continuum';
import { DATE_FULL_STRING_FORMAT } from '../../../constants/formats';

export default ({
  scale,
  momentDate,
  hoursNumber,
  dateStats: { invoiced, planned, toPlan },
}) => (
  <IndicatorsWrapper k={scale}>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SpaceAround>
        <FlexText>
          <RedDescript>{`WK ${momentDate.isoWeek()}`} </RedDescript>
          <BoldTitle>{momentDate.format(DATE_FULL_STRING_FORMAT)}</BoldTitle>
        </FlexText>

        <FlexText>
          <MarginRight>
            <Title>To plan:</Title>
          </MarginRight>
          {toPlan}
        </FlexText>

        <FlexText>
          <Title>Planned:</Title> <RedBoldDescript>{planned}</RedBoldDescript>
        </FlexText>

        <FlexText>
          <Title>Invoiced:</Title> <RedBoldDescript>{invoiced}</RedBoldDescript>
        </FlexText>
      </SpaceAround>

      <HoursList hoursNumber={hoursNumber} />
    </div>

    <div
      style={{
        height: '100%',
        width: `${DAY_BORDER_WIDTH}px`,
        backgroundColor: secondaryBorderColor,
      }}
    />
  </IndicatorsWrapper>
);

const IndicatorsWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${DAY_TITLE_HEIGHT}px;
  background: ${secondary};
  border-bottom: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  font-size: ${p => `${13 * p.k > 18 ? 18 : 13 * p.k}px`};
`;

const SpaceAround = styled.div`
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
`;

const BoldTitle = styled.p`
  font-weight: 700;
`;

const Title = styled.p`
  font-weight: 400;
`;

const FlexText = styled.div`
  display: flex;
  align-items: center;
`;

const RedBoldDescript = styled.span`
  margin-left: 3px;
  font-weight: 700;
  color: #e36969;
`;

const RedDescript = styled.span`
  margin-right: 20px;
  font-weight: 500;
  color: #e36969;
`;

const MarginRight = styled.div`
  margin-right: 3px;
`;
