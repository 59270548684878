import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { MdClear } from 'react-icons/md';
import { useDebouncedCallback } from 'use-debounce';

import { SettingOutlined } from '@ant-design/icons';

import t from '../../../context/actionsTypes';
import API from '../../../api';
import { secondary } from '../../../constants/stylesConstants';
import { useRootContext } from '../../../context';
import { DAY_TITLE_HEIGHT } from '../../../constants/continuum';

import { searchEmployees } from '../../../context/actions';
import { handleServerError } from '../../../utils/errorHandlers';

export default React.memo(({ openModal }) => {
  const {
    state: { dates, visibleEmployees },
    dispatch,
  } = useRootContext();

  const [inputValue, setInputValue] = React.useState('');

  const filterHandler = query => {
    const foundEmployees = visibleEmployees.filter(employee => {
      const {
        last_name: lastName,
        first_name: firstName,
        allowed_methods: methods,
        establishment_name: establishmentName,
      } = employee;

      const hasDesiredMethod = Object.keys(methods).find(method =>
        method.toLowerCase().startsWith(query)
      );

      return (
        hasDesiredMethod ||
        lastName.toLowerCase().startsWith(query) ||
        firstName.toLowerCase().startsWith(query) ||
        establishmentName.toLowerCase().startsWith(query)
      );
    });

    return foundEmployees;
  };

  const updateDateSummaryHandler = async filteredEmployees => {
    const startDate = dates[0].date;
    const endDate = dates[dates.length - 1].date;
    const employeeIds = filteredEmployees
      .filter(({ visible }) => visible)
      .map(({ id }) => id);

    try {
      const updatedSummary = await API.updateDaysSummary(
        startDate,
        endDate,
        employeeIds
      );
      dispatch({
        type: t.UPDATE_DAYS_SUMMARY,
        payload: updatedSummary,
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const [debouncedInput] = useDebouncedCallback(query => {
    const foundEmployees = filterHandler(query.toLowerCase());
    searchEmployees(dispatch, foundEmployees);

    if (foundEmployees.length) updateDateSummaryHandler(foundEmployees);
  }, 500);

  return (
    <Container>
      <SpaceBetween>
        <InputRelative>
          <InputAnt
            value={inputValue}
            type="text"
            placeholder="Employee or establishment"
            onChange={({ target }) => {
              setInputValue(target.value);
              debouncedInput(target.value);
            }}
          />

          <StyledMdClear
            onClick={async () => {
              if (!!inputValue) {
                await setInputValue('');
                searchEmployees(dispatch, visibleEmployees);
                updateDateSummaryHandler(visibleEmployees);
              }
            }}
          />
        </InputRelative>
      </SpaceBetween>

      <SettingOutlined
        onClick={openModal}
        style={{ fontSize: '25px', cursor: 'pointer' }}
      />
    </Container>
  );
});

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  height: ${DAY_TITLE_HEIGHT}px;
  border-right: 1px solid #e2e2e2;
  background: ${secondary};
  border-bottom: 1px solid #e2e2e2;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputAnt = styled(Input)`
  border-radius: 4px;
  width: 93%;
  padding-right: 20px;
  border: 1px solid #c1c1c1;
`;

const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputRelative = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledMdClear = styled(MdClear)`
  position: absolute;
  right: 18px;
  width: 13px;
  height: 13px;
  cursor: pointer;
`;
