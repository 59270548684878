import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TimePicker, DatePicker } from 'formik-antd';
import { message, Radio, InputNumber } from 'antd';
import NProgress from 'nprogress';
import {
  MINS_IN_STEP,
  TIME_FORMAT,
  DATE_FORMAT,
} from '../../../constants/formats';
import {
  text,
  basic,
  primary,
  secondaryBorderColor,
} from '../../../constants/stylesConstants';
import H1 from '../../common/MainHeader';
import {
  SaveBtnWithIcon,
  CancelBtn,
  DeletePlanningBtnWithIcon as DelBtn,
} from '../../common/Button';
import Modal from '../Modal';
import ConfirmMessage from './СonfirmMessage';
import {
  onChangeDateTime,
  getDisabledEndDate,
  onChangStartDate,
} from '../../../utils/datesUtils';
import {
  validateDuration,
  normalizeNumberValue,
  validateWeekDays,
} from '../../../utils/validation';
import { MarginRight } from '../../common/styledComponents';
import Pattern from './Pattern';
import ConflictList from './ConflictList';
import { useRootContext } from '../../../context';
import {
  createRecurrence,
  updateRecurrence,
  deleteReccurence,
} from '../../../context/actions';
import { recurrenceSuccesMsg } from '../../../constants/successMsgs';
import { SpecificError } from '../../common/Messages';
import { createRecurrencePayload } from './payloadMapping';
import Delete from '../Deleting';

const getDisabledStartDate = (startValue, endDate, activityStart) => {
  if (!startValue || !endDate) return false;

  return (
    moment(activityStart, DATE_FORMAT).valueOf() > moment(startValue).valueOf()
  );
};

const taskSchema = Yup.object().shape({
  startTime: Yup.date().required(),
  endTime: Yup.date().required(),
  startLoop: Yup.date().required(),
  endLoop: Yup.date().required(),
});

const Recurrence = ({
  activityReqObj,
  hideCommon,
  hideRucurrence,
  recurrenceId,
  initPatternType,
  initPatternData,
  initRecurrenceRange,
}) => {
  const { dispatch } = useRootContext();
  const [pattern, setPattern] = useState(initPatternType);
  const [patternData, setPatternData] = useState(initPatternData);
  const [isVisible, setIsVisible] = useState(true);
  const [conflicts, setConflicts] = useState([]);
  const [regectedPayload, setRegectedPayload] = useState();
  const [durationError, setDurationError] = useState(null);
  const [weekDaysError, setWeekDaysError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const setPatternField = (value, field) =>
    setPatternData({ ...patternData, [field]: value });

  const resetPatternData = useCallback(() => {
    setPatternData(initPatternData);
  }, []);

  const initialValues = {
    startTime: moment(activityReqObj.start_time, TIME_FORMAT),
    endTime: moment(activityReqObj.end_time, TIME_FORMAT),
    startLoop: moment(activityReqObj.start_date, DATE_FORMAT),
    endLoop: moment(activityReqObj.end_date, DATE_FORMAT),
    ...initRecurrenceRange,
  };

  useEffect(() => {
    if (!isVisible) setIsVisible(true);
  }, [patternData.dayNumber]);

  const onSubmit = async values => {
    let isValid = false;
    isValid = validateDuration(
      {
        ...values,
        startDate: moment(activityReqObj.start_date, DATE_FORMAT),
        endDate: moment(activityReqObj.end_date, DATE_FORMAT),
      },
      setDurationError,
      durationError
    );

    if (pattern === 'weekly') {
      isValid = validateWeekDays(
        patternData.checkedDays,
        setWeekDaysError,
        weekDaysError
      );
    }
    if (!isValid) return;

    NProgress.start();
    NProgress.set(0.7);

    const reqObj = createRecurrencePayload(
      values,
      activityReqObj,
      pattern,
      patternData
    );

    const errors = recurrenceId
      ? await updateRecurrence(dispatch, recurrenceId, reqObj)
      : await createRecurrence(dispatch, reqObj);

    NProgress.done();

    if (errors) {
      setRegectedPayload(reqObj);
      setConflicts(Object.values(errors));
    } else {
      message.success(recurrenceSuccesMsg);
      setTimeout(() => hideCommon(), 1000);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={taskSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        const { startTime, endTime, endLoop, startLoop } = values;
        return (
          <Container>
            <Form>
              <Title>
                <H1 type="modal">Appointment Recurrence</H1>
              </Title>
              <ChoicesContent>
                <ChoiceWrapper>
                  <Legend>Appointment time</Legend>
                  <FlexRowCenter>
                    <ChangeType>Start:</ChangeType>
                    <TimePicker
                      size="default"
                      name="startTime"
                      hideDisabledOptions
                      onChange={value => {
                        onChangeDateTime(
                          value,
                          'startTime',
                          setFieldValue,
                          startTime
                        );
                        validateDuration(
                          {
                            ...values,
                            startDate: moment(
                              activityReqObj.start_date,
                              DATE_FORMAT
                            ),
                            endDate: moment(
                              activityReqObj.end_date,
                              DATE_FORMAT
                            ),
                            startTime: value,
                          },
                          setDurationError,
                          durationError
                        );
                      }}
                      format={TIME_FORMAT}
                      placeholder="start time"
                      minuteStep={MINS_IN_STEP}
                      disabled={isSubmitting}
                    />
                  </FlexRowCenter>
                  <FlexRowCenter>
                    <ChangeType>End:</ChangeType>
                    <TimePicker
                      size="default"
                      name="endTime"
                      hideDisabledOptions
                      onChange={value => {
                        onChangeDateTime(
                          value,
                          'endTime',
                          setFieldValue,
                          endTime
                        );
                        validateDuration(
                          {
                            ...values,
                            startDate: moment(
                              activityReqObj.start_date,
                              DATE_FORMAT
                            ),
                            endDate: moment(
                              activityReqObj.end_date,
                              DATE_FORMAT
                            ),
                            endTime: value,
                          },
                          setDurationError,
                          durationError
                        );
                      }}
                      format={TIME_FORMAT}
                      placeholder="end time"
                      minuteStep={MINS_IN_STEP}
                      disabled={isSubmitting}
                    />
                  </FlexRowCenter>
                  <SpecificError>{durationError}</SpecificError>
                </ChoiceWrapper>
                <ChoiceWrapper>
                  <Legend>Recurrence pattern</Legend>
                  <FlexRow>
                    <Radio.Group
                      onChange={e => {
                        setPattern(e.target.value);
                        resetPatternData();
                      }}
                      disabled={isSubmitting}
                      value={pattern}
                    >
                      <StyledRadio value="daily">Daily</StyledRadio>
                      <StyledRadio value="weekly">Weekly</StyledRadio>
                      <StyledRadio value="monthly">Monthly</StyledRadio>
                      <StyledRadio value="yearly">Yearly</StyledRadio>
                    </Radio.Group>

                    <Pattern
                      pattern={pattern}
                      patternData={patternData}
                      isSubmitting={isSubmitting}
                      weekDaysError={weekDaysError}
                      setPatternField={setPatternField}
                      setWeekDaysError={setWeekDaysError}
                    />
                  </FlexRow>
                </ChoiceWrapper>

                <ChoiceWrapper>
                  <Legend>Range of recurrence</Legend>
                  <FlexRow>
                    <FlexRowCenter>
                      <StartLoop>Start: </StartLoop>
                      <DatePicker
                        disabledDate={value => {
                          return getDisabledStartDate(
                            value,
                            endLoop,
                            activityReqObj.start_date
                          );
                        }}
                        name="startLoop"
                        onChange={startDate => {
                          return onChangStartDate(
                            startDate,
                            endLoop,
                            'startLoop',
                            'endLoop',
                            setFieldValue,
                            startLoop
                          );
                        }}
                        format={DATE_FORMAT}
                        hideDisabledOptions
                        size="default"
                        disabled={isSubmitting}
                      />
                    </FlexRowCenter>

                    <EndLoop>
                      <Radio.Group
                        value={patternData.isActivitiesNumberSet}
                        onChange={e => {
                          setPatternField(
                            e.target.value,
                            'isActivitiesNumberSet'
                          );
                        }}
                        disabled={isSubmitting}
                      >
                        <FlexRowCenter>
                          <StyledRadio value={false}>End by:</StyledRadio>
                          <DatePicker
                            onChange={date => {
                              return onChangeDateTime(
                                date,
                                'endLoop',
                                setFieldValue,
                                endLoop
                              );
                            }}
                            disabledDate={value => {
                              return getDisabledEndDate(value, startLoop);
                            }}
                            name="endLoop"
                            disabled={isSubmitting}
                            format={DATE_FORMAT}
                            size="default"
                          />
                        </FlexRowCenter>

                        <FlexRowCenter>
                          <StyledRadio value>End after: </StyledRadio>
                          <StyledInput
                            value={patternData.activitiesNumber}
                            min={2}
                            onChange={value => {
                              setPatternField(
                                normalizeNumberValue(value),
                                'activitiesNumber'
                              );
                            }}
                            disabled={
                              isSubmitting || !patternData.isActivitiesNumberSet
                            }
                            size="small"
                          />
                          occurrences
                        </FlexRowCenter>
                      </Radio.Group>
                    </EndLoop>
                  </FlexRow>
                </ChoiceWrapper>
              </ChoicesContent>
              <JustifyCenter>
                <MarginRight>
                  <CancelBtn onClick={hideRucurrence} type="button">
                    Cancel
                  </CancelBtn>
                </MarginRight>

                {recurrenceId && (
                  <MarginRight>
                    <DelBtn
                      openDeleteModal={() => setIsDeleting(true)}
                      type="button"
                    >
                      Remove Recurrence
                    </DelBtn>
                  </MarginRight>
                )}

                <SaveBtnWithIcon type="submit">OK</SaveBtnWithIcon>
              </JustifyCenter>
              {pattern === 'monthly' &&
                isVisible &&
                patternData.dayNumber >= 30 && (
                  <Modal onHideModal={() => setIsVisible(false)}>
                    <ConfirmMessage
                      hideConfirmMessage={() => setIsVisible(false)}
                    />
                  </Modal>
                )}
            </Form>
            {!!conflicts.length && (
              <Modal onHideModal={() => setConflicts([])}>
                <ConflictList
                  recurrenceId={recurrenceId}
                  regectedPayload={regectedPayload}
                  conflicts={conflicts}
                  setConflicts={setConflicts}
                  hideCommon={hideCommon}
                  close={() => setConflicts([])}
                />
              </Modal>
            )}
            {isDeleting && (
              <Modal onHideModal={() => setIsDeleting(false)}>
                <Delete
                  content="Are you sure you want to delete this recurrence?"
                  disabled={isSubmitting}
                  resolveCallback={() => {
                    deleteReccurence(dispatch, recurrenceId);
                  }}
                  rejectCallback={() => setIsDeleting(false)}
                />
              </Modal>
            )}
          </Container>
        );
      }}
    </Formik>
  );
};

export default Recurrence;

const Container = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 660px;
  height: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
  border-radius: 10px 10px;
  background: ${basic};
  color: ${text};
  overflow: hidden;
  padding-bottom: 25px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${basic};
  height: 60px;
  background: ${primary};
`;

const ChoicesContent = styled.div`
  padding: 25px 30px 0px 29px;

  /* Interrupting default ant styles for Checkbox */

  .ant-checkbox + span {
    color: ${text};
  }
`;

const ChoiceWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${secondaryBorderColor};
  &:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  /* Interrupting default ant styles for Select */

  .ant-select-selection,
  .ant-select-selection--single {
    border: 1px solid #c1c1c1;
  }
`;

const Legend = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const FlexRow = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${text};
  &:last-child {
    margin-bottom: 0px;
  }
`;

const FlexRowCenter = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  align-self: flex-start;
  color: ${text};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const ChangeType = styled.p`
  width: 70px;
  flex-shrink: 0;
  font-size: 15px;
  font-weight: 500;
`;

const StyledRadio = styled(Radio)`
  height: '30px';
  display: block;
  line-height: '30px';
  color: ${text};
`;

const StyledInput = styled(InputNumber)`
  margin: 0px 5px;
  width: 60px;
`;

const StartLoop = styled.div`
  width: 45px;
  font-size: 15px;
`;

const EndLoop = styled.div`
  margin-left: 25px;
  flex-basis: 55%;
`;

const JustifyCenter = styled.div`
  display: flex;
  padding: 0px 30px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
`;
