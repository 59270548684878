import React from 'react';
import styled from 'styled-components';

import SearchPanel from './Search/SearchPanel';
import ListOrders from './ListOrders';
import { GeneralError } from '../common/Messages';
import { useRootContext } from '../../context';
import { getUnplannedWorkOrders } from '../../context/actions';

import arrowRightSvg from '../../public/icons/arrowRight.svg';
import arrowReverseSvg from '../../public/icons/arrowReverse.svg';
import {
  basic,
  border,
  primary,
  transition,
} from '../../constants/stylesConstants';

export default ({ board, grid }) => {
  const { dispatch } = useRootContext();

  const [isOpen, setIsOpen] = React.useState(false);
  const [fetchError, setFetchError] = React.useState(null);
  const onPressArrowOpen = () => setIsOpen(prevState => !prevState);

  React.useEffect(() => {
    getUnplannedWorkOrders(dispatch, setFetchError);
  }, []);

  return (
    <UnplannedOrdersWrapper isOpen={isOpen}>
      {fetchError ? (
        <GeneralError>{fetchError}</GeneralError>
      ) : (
        <>
          <SearchPanel />
          <ListOrders board={board} grid={grid} />
        </>
      )}

      <Circle onClick={onPressArrowOpen}>
        {isOpen ? <ArrowRightIcon /> : <ArrowReverseIcon />}
      </Circle>
    </UnplannedOrdersWrapper>
  );
};

const UnplannedOrdersWrapper = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isOpen',
})`
  position: absolute;
  z-index: 90;
  display: flex;
  flex-direction: column;
  width: 609px;
  height: 100%;
  background: ${basic};
  border: ${border};
  top: 0;
  right: 0;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: ${transition};
  padding-bottom: 15px;
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: -39px;
  transform: translateY(-50%);
  width: 39px;
  height: 78px;
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  background-color: ${primary};
  transition: ${transition};
  cursor: pointer;
`;

const ArrowRightIcon = styled(arrowRightSvg)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const ArrowReverseIcon = styled(arrowReverseSvg)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
