import GMS from '../context/globalMutableStore';
import { DAY_TITLE_HEIGHT } from '../constants/continuum';

export const getScrollDirections = (
  { clientX, clientY },
  planeShift,
  gap = 40
) => {
  const { innerWidth, innerHeight } = window;
  const directions = [0, 0, 0, 0];
  // 0 - top, 1 - right, 2 - bottom, 3 - left
  if (clientY < planeShift.y + DAY_TITLE_HEIGHT + gap)
    directions[0] = Math.random();
  if (clientX > innerWidth - gap) directions[1] = Math.random();
  if (clientY > innerHeight - gap) directions[2] = Math.random();
  if (clientX < planeShift.x + gap) directions[3] = Math.random();
  return directions;
};

const changeScrollProperties = (
  board,
  step = 1,
  limit = 0,
  [top, right, bottom, left]
) => {
  /* eslint-disable operator-assignment */
  const {
    scrollTop: sT,
    scrollLeft: sL,
    scrollWidth: sW,
    scrollHeight: sH,
    offsetWidth: oW,
    offsetHeight: oH,
  } = board;

  if (top) board.scrollTop = sT - step > 0 ? sT - step : 0;
  if (left) board.scrollLeft = sL - step > limit ? sL - step : limit;
  if (right) board.scrollLeft = sL + step < sW - limit - oW ? sL + step : sL;
  if (bottom)
    board.scrollTop = sT + step < sH - oH + DAY_TITLE_HEIGHT ? sT + step : sT;
  /* eslint-enable operator-assignment */
};

const scroll = ({ eMove, board, speed, limit, planeShift, callback }) => {
  GMS.scrollDirections = getScrollDirections(eMove, planeShift);
  const initSum = GMS.scrollDirections.reduce((a, b) => a + b, 0);

  if (initSum) {
    let prevTime = performance.now();
    requestAnimationFrame(function requestFrame(time) {
      const currentSum = GMS.scrollDirections.reduce((a, b) => a + b, 0);
      if (currentSum && currentSum === initSum) {
        let dt = time - prevTime;
        if (dt < 1) dt = 16;

        let step = Math.round((dt / 1000) * speed);
        if (step < 1) step = 1;

        changeScrollProperties(board, step, limit, GMS.scrollDirections);
        callback();

        prevTime = time;
        requestAnimationFrame(requestFrame);
      }
    });
  }
};

export default scroll;
